import httpClient from '@/clients/httpClient';
import { BffInteractiveCommentsResponse } from '@/interfaces/bff';
import { BaseParamsWithLocale } from '@/interfaces/services';
import { validateEmpty } from '@/services/utilities';

/* URLs */
const getInteractiveCommentListUrl = '/api/instances/{instanceId}/interactive-comments';

/*
   * Interactive comment list component
   * Used on overview page for vibe template
 */
// eslint-disable-next-line import/prefer-default-export
export const getInteractiveComments = async ({
    instanceId,
    waveId,
    locale,
}: BaseParamsWithLocale): Promise<BffInteractiveCommentsResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params = { waveId, locale };

    let url = getInteractiveCommentListUrl;
    url = url.replace('{instanceId}', instanceId);

    return httpClient.get(
        url,
        { params },
    )
        .then((response: BffInteractiveCommentsResponse) => response);
};
