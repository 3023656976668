import { BffHeatmapData, BffHeatmapResponse } from '@/interfaces/bff';

export const surveyFiltersHeatmapData: BffHeatmapData = {
    type: 'absolute',
    columns: [
    '#all#',
    'Not available',
    'male',
    'female'
],
    data: [
    {
        externalVariableNames: 'v_1,v_25,v_32,v_33',
        id: 722,
        label: 'Agility',
        metaname: 'agility',
        min: 1,
        max: 5,
        values: [
            {
                value: 1.6363636,
                type: 'normal'
            },
            {
                value: 1.6363636,
                type: 'normal'
            },
            {
                value: -1,
                type: 'missing'
            },
            {
                value: -1,
                type: 'missing'
            }
        ]
    },
    {
        externalVariableNames: 'v_40',
        id: 727,
        label: 'Collaboration',
        metaname: 'collaboration',
        min: 1,
        max: 5,
        values: [
            {
                value: 2,
                type: 'normal'
            },
            {
                value: 1.7,
                type: 'normal'
            },
            {
                value: 2.3,
                type: 'normal'
            },
            {
                value: -1,
                type: 'missing'
            }
        ]
    },
    {
        externalVariableNames: 'v_39',
        id: 729,
        label: 'Communication',
        metaname: 'communication',
        min: 1,
        max: 5,
        values: [
            {
                value: 2.6666667,
                type: 'normal'
            },
            {
                value: 2.6666667,
                type: 'normal'
            },
            {
                value: -1,
                type: 'missing'
            },
            {
                value: -1,
                type: 'missing'
            }
        ]
    },
    {
        externalVariableNames: 'v_38',
        id: 731,
        label: 'Health',
        metaname: 'health',
        min: 1,
        max: 5,
        values: [
            {
                value: 1.2,
                type: 'normal'
            },
            {
                value: 1.0,
                type: 'normal'
            },
            {
                value: 1.4,
                type: 'normal'
            },
            {
                value: -1,
                type: 'missing'
            }
        ]
    },
    {
        externalVariableNames: 'v_23,v_26,v_27,v_28,v_29,v_30,v_31',
        id: 735,
        label: 'Performance',
        metaname: 'performance',
        min: 1,
        max: 4,
        values: [
            {
                value: 1.8,
                type: 'normal'
            },
            {
                value: 1.6,
                type: 'normal'
            },
            {
                value: -1,
                type: 'missing'
            },
            {
                value: 2,
                type: 'normal'
            }
        ]
    },
    {
        externalVariableNames: 'v_37',
        id: 743,
        label: 'Security',
        metaname: 'security',
        min: 1,
        max: 5,
        values: [
            {
                value: 2,
                type: 'normal'
            },
            {
                value: 2,
                type: 'normal'
            },
            {
                value: -1,
                type: 'missing'
            },
            {
                value: -1,
                type: 'missing'
            }
        ]
    },
    {
        externalVariableNames: 'v_24,v_41',
        id: 745,
        label: 'Unassigned',
        metaname: 'Unassigned',
        min: 1,
        max: 5,
        values: [
            {
                value: 2.3333333,
                type: 'normal'
            },
            {
                value: 2.3333333,
                type: 'normal'
            },
            {
                value: -1,
                type: 'missing'
            },
            {
                value: -1,
                type: 'missing'
            }
        ]
    }
],
    dataType: 'category',
    splitVariable: 'u_gender',
    constructLabel: 'Guide (Dashbo)',
    locale: 'en_GB'
}

export const surveyFiltersHeatmapResponse: BffHeatmapResponse = {
    status: 200,
    data: surveyFiltersHeatmapData,
};
