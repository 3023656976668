import { ActionContext } from 'vuex';
import { NpsStoreState } from '@/interfaces/stores';
import { Metric } from '@/interfaces/visual';
import { BaseParamsWithLocale } from '@/interfaces/services';
import { getNPSDV } from '@/services/dashboardServiceDV';
import { getNPS } from '@/services/npsService';
import { mapNPSToMetrics } from '@/services/ModelMapperService';

export const getDefaultStateNps = (): NpsStoreState => ({
    nps: [],
});

const npsStore = {
    namespaced: true,
    state: getDefaultStateNps(),
    mutations: {
        resetState(state: NpsStoreState): void {
            Object.assign(state, getDefaultStateNps());
        },
        setNps(state: NpsStoreState, nps: Metric[]): void {
            state.nps = nps;
        },
    },
    actions: {
        async loadNps({
            commit,
            dispatch,
            rootState,
            rootGetters,
        }: ActionContext<NpsStoreState, any>): Promise<any> {
            const params: BaseParamsWithLocale = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                locale: rootState.instance.locale,
            };

            let request;

            if (rootGetters['instance/isDVDashboard']) {
                request = getNPSDV(
                    params.instanceId,
                    params.waveId,
                    rootState.instance.kpiConstruct,
                    params.locale,
                );
            } else {
                request = getNPS(params);
            }

            return request
                .then((res) => {
                    const nps = mapNPSToMetrics(res.data);
                    commit('setNps', nps);
                }).catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },
    },
};

export default npsStore;
