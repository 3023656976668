import {
    BffKpi,
    BffKpiFavorable, BffKpiFavorableResponse,
    BffKpiFavorableResponseData,
    BffKpiResponse,
    BffKpiResponseData
} from '@/interfaces/bff';

export const positiveTeamPerformanceScore: BffKpi = {
    bestInClass: 3,
    lastMean: 2,
    lastWaveEndDate: (new Date('2020-05-11')).toISOString(),
    max: 5,
    mean: 3.7,
    min: 1,
};

export const positiveTeamPerformanceScoreFavorable: BffKpiFavorable = {
    bestInClass: .6,
    lastFavorable: .6,
    lastWaveEndDate: (new Date('2020-05-11')).toISOString(),
    favorable: .75,
};

export const negativeTeamPerformanceScoreFavorable: BffKpiFavorable = {
    bestInClass: .6,
    lastFavorable: .6,
    lastWaveEndDate: (new Date('2020-05-11')).toISOString(),
    favorable: .3,
};

export const negativeTeamPerformanceScoreFavorableNoLast: BffKpiFavorable = {
    bestInClass: -1,
    lastFavorable: -1,
    lastWaveEndDate: (new Date('2020-05-11')).toISOString(),
    favorable: .3,
};

export const positiveTeamPerformanceScoreData: BffKpiResponseData = {
    kpi: positiveTeamPerformanceScore,
    constructLabel: 'Team Performance Score',
    locale: 'en_US',
};

export const positiveTeamPerformanceScoreFavorableData: BffKpiFavorableResponseData = {
    kpi: positiveTeamPerformanceScoreFavorable,
    constructLabel: 'Team Performance Score',
    locale: 'en_US',
};

export const positiveTeamPerforanceScoreResponse: BffKpiResponse = {
    status: 200,
    data: positiveTeamPerformanceScoreData,
};

export const positiveTeamPerforanceScoreFavorableResponse: BffKpiFavorableResponse = {
    status: 200,
    data: positiveTeamPerformanceScoreFavorableData,
};

export const negativeTeamPerformanceScore: BffKpi = {
    bestInClass: 3,
    lastMean: 3,
    lastWaveEndDate: (new Date('2020-05-11')).toISOString(),
    max: 5,
    mean: 1.7,
    min: 1,
};

export const negativeTeamPerformanceScoreData: BffKpiResponseData = {
    kpi: negativeTeamPerformanceScore,
    constructLabel: 'Team Performance Score',
    locale: 'en_US',
};

export const negativeTeamPerforanceScoreResponse: BffKpiResponse = {
    status: 200,
    data: negativeTeamPerformanceScoreData,
};

export const emptyTeamPerformanceScore: BffKpi = {
    bestInClass: -1,
    lastMean: -1,
    lastWaveEndDate: null,
    max: 5,
    mean: -1,
    min: 1,
};

export const emptyTeamPerformanceScoreData: BffKpiResponseData = {
    kpi: emptyTeamPerformanceScore,
    constructLabel: 'Team Performance Score',
    locale: 'en_US',
};

export const emptyTeamPerforanceScoreResponse: BffKpiResponse = {
    status: 200,
    data: emptyTeamPerformanceScoreData,
};

export const teamPerformanceScores: BffKpiResponseData[] = [
    positiveTeamPerformanceScoreData,
    negativeTeamPerformanceScoreData,
];
