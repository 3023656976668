import httpClient from '@/clients/httpClient';
import {
    BffCategoryFavorableResponse,
    BffCategoryRaterGroupsResponse,
    BffCategoryResponse,
} from '@/interfaces/bff';
import {
    AggregatedCategoryListParams,
    AggregatedCategoryListPayload,
    CategoryListParams,
    CategoryListPayload,
    CategoryRaterGroupsParams,
    CategoryRaterGroupsPayload,
} from '@/interfaces/services';
import {
    allArea,
    measureMean,
    raterGroupAll,
} from '@/constants';
import { validateEmpty } from '@/services/utilities';

/* URLs */
const getCategoryListUrl = '/api/instances/{instanceId}/categories';
const getAreaCategoryListUrl = '/api/instances/{instanceId}/categories/area';
const getAreaAggregatedCategoryListUrl = '/api/instances/{instanceId}/categories/aggregated/area';
const getCategoryScoresForRaterGroupsUrl = '/api/instances/{instanceId}/categories/area/group-comparison';

/*
   * Category list component
   * Used on overview page for category template and on dev/strength area page for multi rater template
 */
export const getCategories = async ({
    instanceId,
    waveId,
    locale,
    measure = measureMean,
    area,
    raterGroup,
}: CategoryListParams): Promise<BffCategoryResponse | BffCategoryFavorableResponse> => {
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Instance-ID', instanceId);

    const params: CategoryListPayload = {
        waveId,
        locale,
        measure,
        area: (area && area !== allArea) ? area : undefined,
        raterGroup: (raterGroup && raterGroup !== raterGroupAll) ? raterGroup : undefined,
    };

    let url = area ? getAreaCategoryListUrl : getCategoryListUrl;
    url = url.replace('{instanceId}', instanceId);

    return httpClient.get(
        url,
        { params },
    )
        .then((response: BffCategoryResponse | BffCategoryFavorableResponse) => response);
};

/*
   * Category list component (aggregated)
   * Used on dev/strength page for aggregated template
 */
export const getCategoriesAggregated = async ({
    instanceId,
    guideId,
    locale,
    area,
}: AggregatedCategoryListParams): Promise<BffCategoryResponse | BffCategoryFavorableResponse> => {
    validateEmpty('Guide-ID', guideId);
    validateEmpty('Instance-ID', instanceId);

    const params: AggregatedCategoryListPayload = {
        guideId,
        locale,
        area: (area && area !== allArea) ? area : undefined,
    };

    let url = getAreaAggregatedCategoryListUrl;
    url = url.replace('{instanceId}', instanceId);

    return httpClient.get(
        url,
        { params },
    )
        .then((response: BffCategoryResponse | BffCategoryFavorableResponse) => response);
};

/*
   * Rater groups category score component
   * Used on category page for multi rater template
 */
export const getCategoryScoreForRaterGroups = async ({
    instanceId,
    waveId,
    categoryIdFilter,
}: CategoryRaterGroupsParams): Promise<BffCategoryRaterGroupsResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params: CategoryRaterGroupsPayload = {
        waveId,
        categoryIdFilter,
    };
    let url = getCategoryScoresForRaterGroupsUrl;
    url = url.replace('{instanceId}', instanceId);

    return httpClient.get(
        url,
        { params },
    )
        .then((response) => response);
};
