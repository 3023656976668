import { selectAllKeys } from '@/constants';
import i18nLocalization from '@/i18n/i18nLocalization';
import {
    CmsArticle,
    CmsArticleResponse,
    CmsArticleResponseData,
    CmsArticlesResponse,
    CmsArticlesResponseData
} from '@/interfaces/cms';
import { OptionItem } from '@/interfaces/visual';

export const dummyArticles: CmsArticle[] = [
    {
        uuid: 'da8083e4-4c13-11ea-b77f-2e728ce88125',
        full_slug: 'aaaaa',
        categoryLabel: 'Taking action',
        content: {
            image: 'https://i.picsum.photos/id/7/1200/700.jpg',
            category: 'taking-action',
            title: 'For Employee Experience Strategies, Think Big And Act Small',
            // eslint-disable-next-line max-len
            teaser: '2019 was the year that employee experience (EX) as a concept gained traction among major businesses. Unfortunately, because it’s a nebulous concept, it’s being applied to point solutions like employee surveys and not being executed as a larger strategy.',
            subtitle: '2019 was the year that employee experience (EX) as a concept gained traction among major businesses. Unfortunately, because it’s a nebulous concept, it’s being applied to point solutions like employee surveys and not being executed as a larger strategy.',
            content: {
                type: 'doc',
                content: [
                    {
                        type: 'heading',
                        attrs: {
                            level: 2,
                        },
                        content: [
                            {
                                text: 'Tip No. 1: EX Works Best As A Mobile-First Initiative',
                                type: 'text'
                            },
                        ],
                    },
                    {
                        type: 'paragraph',
                        content: [
                            {
                                text: 'Consumer applications have created a higher standard for enterprise software players. The boring, meat-and-potatoes productivity software of yesterday is no longer acceptable in an age where employees use intuitive apps that help them complete work tasks from the road. Businesses need to make sure their digital workplace is reflective of modern interfaces and workflows.',
                                type: 'text'
                            },
                        ],
                    },
                    {
                        type: 'heading',
                        attrs: {
                            level: 2,
                        },
                        content: [
                            {
                                text: 'Tip No. 2: Apply CX Principles To EX Strategies',
                                type: 'text'
                            },
                        ],
                    },
                    {
                        type: 'paragraph',
                        content: [
                            {
                                text: 'The Boston Red Sox won the 2018 World Series because the coaches and players performed so well on the field. What many fans don’t realize is there are hundreds of supporting employees behind the scenes who play essential roles in the organization attracting more than 32,000 people to Fenway Park 81-plus times a year. The organization has discovered the powerful and undeniable link between customer experience (CX) and EX. (Full disclosure: The Red Sox are an Akumina customer.)',
                                type: 'text'
                            },
                        ],
                    },
                    {
                        type: 'heading',
                        attrs: {
                            level: 2,
                        },
                        content: [
                            {
                                text: 'Tip No. 3: When In Doubt, Just Ask Employees About New Tech',
                                type: 'text'
                            },
                        ],
                    },
                    {
                        type: 'paragraph',
                        content: [
                            {
                                text: 'Companies that employ a blended workforce composed of a mixture of traditional, remote and front-line workers can encounter difficulty in implementing new technology and policies. One of the simplest ways to reduce friction and ensure a smoother rollout is to have frequent check-ins with your employees to learn more about the problems they experience. Businesses must create a strong two-way communication system with their employees for this very reason. Tech teams that operate within a bubble aren’t effective because all feedback—both positive and negative—contains insights that help them improve.',
                                type: 'text'
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        uuid: '04bb4cb4-4d83-11ea-b77f-2e728ce88126',
        full_slug: 'bbbbb',
        categoryLabel: 'Employee Feedback',
        content: {
            category: 'Employee Feedback',
            image: 'https://images.pexels.com/photos/106344/pexels-photo-106344.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            title: '13 Key Aspects To Consider When Selecting An Employee Engagement Survey Tool',
            teaser: 'Employee engagement surveys are a general measure of the happiness of a business\'s workforce. After all, happy and satisfied employees are typically less likely to leave the company for greener pastures. The feedback from these surveys is crucial in helping the company adapt to the needs of its employees.',
            subtitle: 'Employee engagement surveys are a general measure of the happiness of a business\'s workforce. After all, happy and satisfied employees are typically less likely to leave the company for greener pastures. The feedback from these surveys is crucial in helping the company adapt to the needs of its employees.',
            content: {
                type: 'doc',
                content: [
                    {
                        type: 'paragraph',
                        content: [
                            {
                                text: 'The tools that a business uses to conduct these surveys should demonstrate some basic functionalities that help the company be more effective at managing their workforce and helping them be better at their work-life balance. To help, 13 members of Forbes Human Resources Council explore some of the key aspects an employee engagement survey tool should have to be truly useful to a business.',
                                type: 'text'
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        uuid: '04bb4b88-4d83-11ea-b77f-2e728ce88127',
        full_slug: 'ccccc',
        categoryLabel: 'Employee Feedback',
        content: {
            category: 'Employee Feedback',
            image: 'http://septburkhardt.de/wp-content/uploads/2016/07/questback_weiterbildung_2-2.jpg',
            title: 'Your Employee Recognition System Is Stuck In The Past. Use These 3 Strategies To Give Your Team The Feedback They Need',
            teaser: 'Yearly feedback loops like employee evaluations and promotion reviews have carried us for decades. But once a year is no longer often enough to praise your team -- frankly, it never was.',
            subtitle: 'Yearly feedback loops like employee evaluations and promotion reviews have carried us for decades. But once a year is no longer often enough to praise your team -- frankly, it never was.',
            content: {
                type: 'doc',
                content: [
                    {
                        type: 'paragraph',
                        content: [
                            {
                                text: 'The tools that a business uses to conduct these surveys should demonstrate some basic functionalities that help the company be more effective at managing their workforce and helping them be better at their work-life balance. To help, 13 members of Forbes Human Resources Council explore some of the key aspects an employee engagement survey tool should have to be truly useful to a business.',
                                type: 'text'
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        uuid: '04bb44a8-4d83-11ea-b77f-2e728ce88128',
        full_slug: 'ddddd',
        categoryLabel: 'Leadership',
        content: {
            category: 'Leadership',
            image: 'https://images.pexels.com/photos/1138903/pexels-photo-1138903.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
            title: 'How To Fully Actualize Leadership Potential In Your Organization',
            teaser: 'Once, very early in my career, the leader of the organization I worked for gathered me and my colleagues to share a new initiative he intended to roll out. As I listened, a picture formed in my mind, and I quickly saw several gaps that would derail what was overall a very inspiring plan. I pointed out those gaps in the group, explaining what I had observed and experienced relative to the players essential to his strategy’s success. As you might imagine, my contributions didn’t go over well that day. To say things were tense for me following that meeting would be a huge understatement.',
            subtitle: 'Once, very early in my career, the leader of the organization I worked for gathered me and my colleagues to share a new initiative he intended to roll out. As I listened, a picture formed in my mind, and I quickly saw several gaps that would derail what was overall a very inspiring plan. I pointed out those gaps in the group, explaining what I had observed and experienced relative to the players essential to his strategy’s success. As you might imagine, my contributions didn’t go over well that day. To say things were tense for me following that meeting would be a huge understatement.',
            content: {
                type: 'doc',
                content: [
                    {
                        type: 'paragraph',
                        content: [
                            {
                                text: 'The tools that a business uses to conduct these surveys should demonstrate some basic functionalities that help the company be more effective at managing their workforce and helping them be better at their work-life balance. To help, 13 members of Forbes Human Resources Council explore some of the key aspects an employee engagement survey tool should have to be truly useful to a business.',
                                type: 'text'
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        uuid: '04bb48d6-4d83-11ea-b77f-2e728ce88129',
        full_slug: 'eeeee',
        categoryLabel: 'Leadership',
        content: {
            category: 'Leadership',
            image: 'https://images.pexels.com/photos/194094/pexels-photo-194094.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            title: 'How Applying Fluidity Can Shape Your Leadership',
            teaser: 'The definition of "leadership" varies from expert to expert, even from person to person. And a good leader in one culture may not be so in another, unless some adjustments are made. It\'s because leadership isn\'t written in stone. It\'s a social construct, and there is no one formula that guarantees the result of a specific leadership.',
            subtitle: 'The definition of "leadership" varies from expert to expert, even from person to person. And a good leader in one culture may not be so in another, unless some adjustments are made. It\'s because leadership isn\'t written in stone. It\'s a social construct, and there is no one formula that guarantees the result of a specific leadership.',
            content: {
                type: 'doc',
                content: [
                    {
                        type: 'paragraph',
                        content: [
                            {
                                text: 'The tools that a business uses to conduct these surveys should demonstrate some basic functionalities that help the company be more effective at managing their workforce and helping them be better at their work-life balance. To help, 13 members of Forbes Human Resources Council explore some of the key aspects an employee engagement survey tool should have to be truly useful to a business.',
                                type: 'text'
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        uuid: '04bb4a48-4d83-11ea-b77f-2e728ce88130',
        full_slug: 'fffff',
        categoryLabel: 'Employee Feedback',
        content: {
            category: 'Employee Feedback',
            image: 'http://septburkhardt.de/wp-content/uploads/2016/07/questback_weiterbildung.jpg',
            title: 'Why An Employee Feedback Software Is Essential For Your Company',
            teaser: 'Employee feedback software is important for your organization because it helps your management to keep a pulse on staff morale and motivation. You need to be aware of whether your workforce is satisfied with their overall work environment. An employee feedback app can assist with this. By understanding your personnel’s needs and fulfilling them you can improve your workplace culture.',
            subtitle: 'Employee feedback software is important for your organization because it helps your management to keep a pulse on staff morale and motivation. You need to be aware of whether your workforce is satisfied with their overall work environment. An employee feedback app can assist with this. By understanding your personnel’s needs and fulfilling them you can improve your workplace culture.',
            content: {
                type: 'doc',
                content: [],
            },
        },
    },
    {
        uuid: '04bb4a48-4d83-11ea-b77f-2e728ce88131',
        full_slug: 'full-article-slug',
        categoryLabel: 'Employee Feedback2',
        content: {
            category: 'Employee Feedback2',
            cardTeaser: [{ video_code: 'wGhTZS' }],
            infographic: [{
                image: {
                    filename: 'https://a.storyblok.com/f/83485/800x3110/f3da6b397b/infographic1.png',
                },
            }],
            image: '//img2.storyblok.com/555x0/f/83485/6016x4016/48061584b6/resources_04.jpeg',
            title: 'Why An Employee Feedback Software Is Essential For Your Company',
            teaser: 'Employee feedback software is important for your organization because it helps your management to keep a pulse on staff morale and motivation. You need to be aware of whether your workforce is satisfied with their overall work environment. An employee feedback app can assist with this. By understanding your personnel’s needs and fulfilling them you can improve your workplace culture.',
            subtitle: 'Employee feedback software is important for your organization because it helps your management to keep a pulse on staff morale and motivation. You need to be aware of whether your workforce is satisfied with their overall work environment. An employee feedback app can assist with this. By understanding your personnel’s needs and fulfilling them you can improve your workplace culture.',
            content: {
                type: 'doc',
                content: [],
            },
        },
    },
];

export const articlesData: CmsArticlesResponseData = {
    articles: dummyArticles,
};

export const articlesResponse: CmsArticlesResponse = {
    status: 200,
    data: articlesData,
};

export const articleResponse: CmsArticleResponse = {
    status: 200,
    data: dummyArticles[6],
};

export const getDummyArticleResponse = (fullSlug: string): CmsArticleResponse => {
    const dummyArticle = dummyArticles.find(article => article.full_slug === fullSlug);
    if (dummyArticle) {
        return {
            status: 200,
            data: dummyArticle,
        }
    }
    return {
        status: 404,
        data: undefined,
    }
}

export const dummyCategories = ((): OptionItem[] => {
    const uniqueCategories = {};
    dummyArticles.forEach((item) => {
        if (!uniqueCategories[item.content.category]) {
            uniqueCategories[item.content.category] = item.categoryLabel;
        }
    });

    const categories: OptionItem[] = [
        {
            metaname: selectAllKeys,
            label: i18nLocalization.t('all') as string,
        },
    ];
    Object.entries(uniqueCategories).forEach(([metaname, label]) => {
        categories.push({
            metaname,
            label,
        } as OptionItem);
    });
    return categories;
})();
