import { ActionContext } from 'vuex';
import { MultiRaterStoreState } from '@/interfaces/stores';
import { RaterGroup } from '@/interfaces/bff';
import { BaseParamsWithLocale } from '@/interfaces/services';
import { getRaterGroups } from '@/services/multiRaterService';
import {
    allArea,
    raterGroupAll,
    raterGroupSelf,
} from '@/constants';
import i18nLocalization from '@/i18n/i18nLocalization';

export const getDefaultStateMultiRater = ():MultiRaterStoreState => ({
    raterGroups: [],
    selectedRaterGroupMetaname: raterGroupAll,
    area: allArea,
});

const multiRaterStore = {
    namespaced: true,
    state: getDefaultStateMultiRater(),
    getters: {
        hasSelfPerception(state: MultiRaterStoreState): boolean {
            return state.raterGroups.filter((group) => (
                group.metaname === raterGroupSelf
            )).length > 0;
        },
        raterGroupsWithoutSelf(state: MultiRaterStoreState): RaterGroup[] {
            return state.raterGroups.filter((group) => (
                group.metaname !== raterGroupSelf
            ));
        },
        selectedRaterGroup(state: MultiRaterStoreState): RaterGroup | undefined | null {
            return state.raterGroups.find((raterGroup) => raterGroup.metaname === state.selectedRaterGroupMetaname);
        },
    },
    mutations: {
        resetState(state: MultiRaterStoreState): void {
            Object.assign(state, getDefaultStateMultiRater());
        },
        setRaterGroups(state: MultiRaterStoreState, raterGroups: RaterGroup[]): void {
            state.raterGroups = raterGroups;
        },
        updateSelectedRaterGroupMetaname(state: MultiRaterStoreState, metaname: string): void {
            state.selectedRaterGroupMetaname = metaname;
        },
        setArea(state: MultiRaterStoreState, area: string): void {
            state.area = area;
        },
    },
    actions: {
        async loadRaterGroups({
            commit,
            dispatch,
            rootState,
        }: ActionContext<MultiRaterStoreState, any>): Promise<any> {
            const params: BaseParamsWithLocale = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                locale: rootState.instance.locale,
            };
            return getRaterGroups(params).then((res) => {
                const { data } = res;
                const raterGroups = [
                    {
                        metaname: raterGroupAll,
                        label: i18nLocalization.t('raterGroupsAll') as string,
                    },
                    ...data.raterGroups,
                ];

                commit('setRaterGroups', raterGroups);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },
    },
};

export default multiRaterStore;
