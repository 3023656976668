import { BffInstanceResponse, BffInstanceResponseConfigurations, BffInstanceResponseData } from '@/interfaces/bff';
import { measureFav, measureMean } from '@/constants';
import { unscoredQuestions } from '@/fixtures/bff/openQuestions';
import {
    construct,
    refreshRate,
    showOverallKpi,
    showResources,
    templateId,
    templateIdMultiCategory,
    templateIdRaterGroups,
    templateIdVibes,
    templateIdAggregated,
} from '@/fixtures';
import { scoredQuestions, scoredQuestionsWithSelfPerception } from '@/fixtures/bff/questionScores';
import { categoryScores } from '@/fixtures/bff/categoryScores';

export const availableLocales = [
    { locale: 'en_US', localeLabel: 'English (US)' },
    { locale: 'en_UK', localeLabel: 'English (UK)' },
    { locale: 'de_DE', localeLabel: 'German / Deutsch' },
    { locale: 'es_ES', localeLabel: 'Spanish / Español' },
];

export const standardLocale = 'en_US';

// ------------------ Questions Only template ------------------------------

export const instanceResponseConfigurations: BffInstanceResponseConfigurations = {
    feedbackConstruct: construct,
    refreshRate,
    mainMeasure: measureMean, // mean | percentFavorable
    historicalTrend: true,
    anonymityThreshold: false,
    theme: 'basic',
};

export const instanceResponseData: BffInstanceResponseData = {
    id: 'question-instance',
    templateId,
    configurations: instanceResponseConfigurations,
    standardLocale,
    availableLocales,
    meta: {
        countScaleQuestions: scoredQuestions.length,
        countCommentQuestions: unscoredQuestions.length,
        waveEnded: false,
    },
};

export const instanceResponse: BffInstanceResponse = {
    status: 200,
    data: instanceResponseData,
};

export const instanceResponseConfigurationsWithAnonym: BffInstanceResponseConfigurations = {
    feedbackConstruct: construct,
    refreshRate,
    mainMeasure: measureMean, // mean | percentFavorable
    historicalTrend: true,
};

export const instanceResponseDataWithAnonym: BffInstanceResponseData = {
    id: 'question-instance',
    templateId,
    configurations: instanceResponseConfigurationsWithAnonym,
    standardLocale: 'en_US',
    availableLocales: [
        { locale: 'en_US', localeLabel: 'English (US)' },
        { locale: 'en_UK', localeLabel: 'English (UK)' },
        { locale: 'de_DE', localeLabel: 'German / Deutsch' },
        { locale: 'es_ES', localeLabel: 'Spanish / Español' },
    ],
    meta: {
        countScaleQuestions: scoredQuestions.length,
        countCommentQuestions: unscoredQuestions.length,
        waveEnded: false,
    },
    totalAnswered: 3,
    anonymityThreshold: 5,
};

export const instanceResponseFavorableConfigurations: BffInstanceResponseConfigurations = {
    feedbackConstruct: construct,
    refreshRate,
    mainMeasure: measureFav, // mean | percentFavorable
    historicalTrend: true,
    anonymityThreshold: false,
};

export const instanceFavorableResponseData: BffInstanceResponseData = {
    id: 'question-instance',
    templateId,
    configurations: instanceResponseFavorableConfigurations,
    standardLocale: 'en_US',
    availableLocales: [
        { locale: 'en_US', localeLabel: 'English (US)' },
        { locale: 'en_UK', localeLabel: 'English (UK)' },
        { locale: 'de_DE', localeLabel: 'German / Deutsch' },
        { locale: 'es_ES', localeLabel: 'Spanish / Español' },
    ],
    meta: {
        countScaleQuestions: scoredQuestions.length,
        countCommentQuestions: unscoredQuestions.length,
        waveEnded: false,
    },
};

export const instanceFavorableResponse: BffInstanceResponse = {
    status: 200,
    data: instanceFavorableResponseData,
};

// ------------------ Multi Categories template ------------------------------

export const instanceMultiCategoryResponseConfigurations: BffInstanceResponseConfigurations = {
    anonymityThreshold: false,
    historicalTrend: false,
    feedbackConstruct: construct,
    refreshRate,
    mainMeasure: measureMean, // mean | percentFavorable
    showOverallKpi,
    showResources,
    widgetFilterVariables: 'tester,language',
};

export const instanceMultiCategoryResponseData: BffInstanceResponseData = {
    id: 'category-instance',
    templateId: templateIdMultiCategory,
    configurations: instanceMultiCategoryResponseConfigurations,
    standardLocale: 'en_US',
    availableLocales: [
        {locale: 'en_US', localeLabel: 'English (US)'},
        {locale: 'de_DE', localeLabel: 'Deutsch (Deutschland)'},
        {locale: 'es_ES', localeLabel: 'Español (España)'}
    ],
    meta: {
        countCategories: categoryScores.length,
        countScaleQuestions: scoredQuestions.length,
        countCommentQuestions: unscoredQuestions.length,
        waveEnded: false,
    },
};

export const instanceMultiCategoryResponse: BffInstanceResponse = {
    status: 200,
    data: instanceMultiCategoryResponseData,
};

export const instanceMultiCategoryFavorableResponseConfigurations: BffInstanceResponseConfigurations = {
    anonymityThreshold: false,
    historicalTrend: false,
    feedbackConstruct: construct,
    refreshRate,
    mainMeasure: measureFav, // mean | percentFavorable
    showOverallKpi,
    showResources
};

export const instanceMultiCategoryFavorableResponseData: BffInstanceResponseData = {
    id: 'category-instance',
    templateId: templateIdMultiCategory,
    configurations: instanceMultiCategoryFavorableResponseConfigurations,
    standardLocale: 'en_US',
    availableLocales: [
        {locale: 'en_US', localeLabel: 'English (US)'},
        {locale: 'de_DE', localeLabel: 'Deutsch (Deutschland)'},
        {locale: 'es_ES', localeLabel: 'Español (España)'}
    ],
    meta: {
        countCategories: categoryScores.length,
        countCommentQuestions: unscoredQuestions.length,
        waveEnded: false,
    },
};

export const instanceMultiCategoryFavorableResponse: BffInstanceResponse = {
    status: 200,
    data: instanceMultiCategoryFavorableResponseData,
};

// ------------------ Rater Groups template ------------------------------

export const instanceRaterGroupsResponseConfigurations: BffInstanceResponseConfigurations = {
    anonymityThreshold: false,
    historicalTrend: false,
    feedbackConstruct: construct,
    refreshRate,
    mainMeasure: measureMean, // mean | percentFavorable
    showOverallKpi,
    showResources
};

export const instanceRaterGroupsResponseData: BffInstanceResponseData = {
    id: 'rater-instance',
    templateId: templateIdRaterGroups,
    configurations: instanceRaterGroupsResponseConfigurations,
    standardLocale: 'en_US',
    availableLocales: [
        {locale: 'en_US', localeLabel: 'English (US)'},
        {locale: 'de_DE', localeLabel: 'Deutsch (Deutschland)'},
        {locale: 'es_ES', localeLabel: 'Español (España)'}
    ],
    meta: {
        countCategories: categoryScores.length,
        countScaleQuestions: scoredQuestionsWithSelfPerception.length,
        countCommentQuestions: unscoredQuestions.length,
        waveEnded: false,
    },
};

export const instanceRaterGroupsResponse: BffInstanceResponse = {
    status: 200,
    data: instanceRaterGroupsResponseData,
};

// ------------------ Vibe template ------------------------------

export const instanceVibesResponseConfigurations: BffInstanceResponseConfigurations = {
    anonymityThreshold: false,
    historicalTrend: false,
    feedbackConstruct: construct,
    refreshRate,
    mainMeasure: measureMean, // mean | percentFavorable
};

export const instanceVibesResponseData: BffInstanceResponseData = {
    id: 'vibe-instance',
    templateId: templateIdVibes,
    configurations: instanceVibesResponseConfigurations,
    standardLocale: 'en_US',
    availableLocales: [
        {locale: 'en_US', localeLabel: 'English (US)'},
        {locale: 'de_DE', localeLabel: 'Deutsch (Deutschland)'},
        {locale: 'es_ES', localeLabel: 'Español (España)'}
    ],
    meta: {
        countCategories: undefined,
        countCommentQuestions: undefined,
        waveEnded: false,
    },
};

export const instanceVibesResponse: BffInstanceResponse = {
    status: 200,
    data: instanceVibesResponseData,
};

// ------------------ Aggregated template ------------------------------

export const instanceAggregatedResponseConfigurations: BffInstanceResponseConfigurations = {
    feedbackConstruct: 'Elements',
    theme: 'basic',
};

export const instanceAggregatedResponseData: BffInstanceResponseData = {
    id: 'aggregated-instance',
    templateId: templateIdAggregated,
    configurations: instanceAggregatedResponseConfigurations,
    standardLocale: 'en_US',
    availableLocales: [
        {locale: 'en_US', localeLabel: 'English (US)'},
        {locale: 'de_DE', localeLabel: 'Deutsch (Deutschland)'},
        {locale: 'es_ES', localeLabel: 'Español (España)'}
    ],
    meta: {
        countCategories: 6,
        countCommentQuestions: 5,
    },
};

export const instanceAggregatedResponse: BffInstanceResponse = {
    status: 200,
    data: instanceAggregatedResponseData,
};


// ------------------- Breakout Config (aka Splits in new templates) ----------------------------

export const instanceWithBreakoutResponseConfigurations: BffInstanceResponseConfigurations = {
    ...instanceResponseConfigurations,
    breakoutVariables: 'tester,language',
    refreshRate: -1,
};

export const instanceWithBreakoutResponseData: BffInstanceResponseData = {
    ...instanceResponseData,
    configurations: instanceWithBreakoutResponseConfigurations,
};

export const instanceWithBreakoutResponse: BffInstanceResponse = {
    status: 200,
    data: instanceWithBreakoutResponseData,
};


// ------------------- Filter Config ----------------------------

export const instanceWithFilterResponseConfigurations: BffInstanceResponseConfigurations = {
    ...instanceResponseConfigurations,
    listFilterVariables: 'tester,language',
};

export const instanceWithFilterResponseData: BffInstanceResponseData = {
    ...instanceResponseData,
    configurations: instanceWithFilterResponseConfigurations,
};

export const instanceWithFilterResponse: BffInstanceResponse = {
    status: 200,
    data: instanceWithFilterResponseData,
};
