import { BffInstanceResponse } from '@/interfaces/bff';
import {
    dummyInstanceId, dummyInstanceIdAggregated,
    dummyInstanceIdFavorable,
    dummyInstanceIdMultiCategory,
    dummyInstanceIdMultiCategoryFavorable,
    dummyInstanceIdRaterGroups, dummyInstanceIdVibes,
    dummyInstanceIdWithSplit,
    dummyInstanceIdWithFilter,
} from '@/fixtures';
import {
    instanceAggregatedResponse,
    instanceFavorableResponse,
    instanceMultiCategoryFavorableResponse,
    instanceMultiCategoryResponse,
    instanceRaterGroupsResponse,
    instanceResponse, instanceVibesResponse,
    instanceWithBreakoutResponse,
    instanceWithFilterResponse,
} from '@/fixtures/bff/instances';
import { validateEmpty } from '@/services/utilities';
import httpClient from '@/clients/httpClient';

export const getInstanceDataUrl = '/api/instance/{instanceId}';

export const getInstanceData = async (
    instanceId: string,
    waveId?: string,
): Promise<BffInstanceResponse> => {
    if (instanceId === dummyInstanceId) {
        return Promise.resolve(instanceResponse);
    }
    if (instanceId === dummyInstanceIdWithSplit) {
        return Promise.resolve(instanceWithBreakoutResponse);
    }
    if (instanceId === dummyInstanceIdWithFilter) {
        return Promise.resolve(instanceWithFilterResponse);
    }
    if (instanceId === dummyInstanceIdFavorable) {
        return Promise.resolve(instanceFavorableResponse);
    }
    if (instanceId === dummyInstanceIdMultiCategory) {
        return Promise.resolve(instanceMultiCategoryResponse);
    }
    if (instanceId === dummyInstanceIdMultiCategoryFavorable) {
        return Promise.resolve(instanceMultiCategoryFavorableResponse);
    }
    if (instanceId === dummyInstanceIdRaterGroups) {
        return Promise.resolve(instanceRaterGroupsResponse);
    }
    if (instanceId === dummyInstanceIdVibes) {
        return Promise.resolve(instanceVibesResponse);
    }
    if (instanceId === dummyInstanceIdAggregated) {
        return Promise.resolve(instanceAggregatedResponse);
    }

    validateEmpty('Instance-ID', instanceId);

    const url = getInstanceDataUrl.replace('{instanceId}', instanceId);
    const params = { waveId };

    return httpClient.get(
        url,
        { params },
    )
        .then((response: BffInstanceResponse) => response);
};
