import httpClient from '@/clients/httpClient';
import { BffHeatmapResponse, BffSplitResponse } from '@/interfaces/bff';
import {
    CategoryHeatmapParams,
    CategoryHeatmapPayload,
    MultiRaterHeatmapParams,
    MultiRaterHeatmapPayload, QuestionFiltersHeatmapPayload, QuestionHeatmapParams, QuestionHeatmapPayload,
} from '@/interfaces/services';
import { addFiltersToParams, validateEmpty } from '@/services/utilities';
import { heatmapDataTypeCategory } from '@/constants';

/* URLs */
export const getMultiRaterHeatmapUrl = '/api/instances/{instanceId}/heatmap/rater-groups';
export const getCategoryHeatmapUrl = '/api/instances/{instanceId}/heatmap/survey-filters';
export const getQuestionHeatmapUrl = '/api/instances/{instanceId}/question/{questionId}/split';

/*
   * Heatmap component for rater groups
   * Used on rater group comparison page for multi rater template
 */
export const getMultiRaterHeatmap = async ({
    instanceId,
    waveId,
    locale,
    dataType,
}: MultiRaterHeatmapParams): Promise<BffHeatmapResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params: MultiRaterHeatmapPayload = {
        waveId,
        locale,
        dataType,
    };
    let url = getMultiRaterHeatmapUrl;
    url = url.replace('{instanceId}', instanceId);

    return httpClient.get(
        url,
        { params },
    )
        .then((response: BffHeatmapResponse) => response);
};

/*
   * Heatmap component for categories
   * Used on categories comparison page for category template (comparison by survey filters)
 */
export const getCategoryHeatmap = async ({
    instanceId,
    waveId,
    locale,
    dataType = heatmapDataTypeCategory,
    measure,
    surveyFilterVariable,
}: CategoryHeatmapParams): Promise<BffHeatmapResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params: CategoryHeatmapPayload = {
        waveId,
        locale,
        surveyFilterVariable,
        dataType,
        measure,
    };
    let url = getCategoryHeatmapUrl;
    url = url.replace('{instanceId}', instanceId);

    return httpClient.get(
        url,
        { params },
    )
        .then((response: BffHeatmapResponse) => response);
};

/*
   * Heatmap component for questions
   * Used on overview page for question template (comparison by splits)
 */
export const getQuestionHeatmap = async ({
    instanceId,
    waveId,
    questionId,
    splitVariable,
    locale,
    selectedFilterScales,
}: QuestionHeatmapParams): Promise<BffSplitResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Question-ID', questionId);
    validateEmpty('Split varname', splitVariable);

    let params: QuestionHeatmapPayload | QuestionFiltersHeatmapPayload = {
        waveId,
        splitVariable,
        locale,
    };

    if (selectedFilterScales && selectedFilterScales.length) {
        const filterParams = addFiltersToParams(selectedFilterScales);
        params = { ...params, ...filterParams };
    }

    let url = getQuestionHeatmapUrl;
    url = url.replace('{instanceId}', instanceId).replace('{questionId}', questionId);
    return httpClient.get(
        url,
        { params },
    )
        .then((response: BffSplitResponse) => response);
};
