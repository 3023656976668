import { ExternalVuetifyTheme } from '@/interfaces/externalVuetifyTheme';

// eslint-disable-next-line import/prefer-default-export
export const neutralTheme4: ExternalVuetifyTheme = {
    primary: {
        lighten1: '#EEEFF3',
        base: '#747C93',
        darken1: '#434755',
    },

    secondary: {
        lighten1: '#EFEFEF',
        base: '#6E7D8C',
        darken1: '#41474F',
    },

    decorative1: '#B73A89',
    decorative2: '#3A4BB7',

    textPrimary: '#212B36',
    textSecondary: '#5E738A',

    criticalPrimary: '#D83543',
    criticalSecondary: '#FACAD1',

    warningPrimary: '#E3A12B',
    warningSecondary: '#FFF2DA',

    informativePrimary: '#673AB7',
    informativeSecondary: '#D6C8F3',

    successPrimary: '#208234',
    successSecondary: '#EBFFED',

    neutralShade: {
        lighten5: '#F4F6F8',
        lighten4: '#CCD5DE',
        lighten3: '#ADB9C6',
        lighten2: '#8D9DAE',
        lighten1: '#76879B',
        base: '#5E738A',
        darken1: '#516579',
        darken2: '#425263',
        darken3: '#333F4D',
        darken4: '#212B36',
    },

    primaryShade: {
        lighten5: '#EEEFF3', // 50
        lighten4: '#D4D8E0', // 100
        lighten3: '#BABECA', // 200
        lighten2: '#9EA3B4', // 300
        lighten1: '#898FA3', // 400
        base: '#747C93', // 500
        darken1: '#666D81', // 600
        darken2: '#545A6A', // 700
        darken3: '#434755', // 800
        darken4: '#30333D', // 900
    },
};
