import httpClient from '@/clients/httpClient';
import { validateEmpty } from '@/services/utilities';
import { CmsArticleResponse, CmsArticlesResponse } from '@/interfaces/cms';
import { ArticleListParams, ArticleListPayload, ArticleParams } from '@/interfaces/services';

/* URLs */
const getArticleListUrl = '/api/instance/{instanceId}/categories/{category}/articles';
/* Single article */
const getArticleUrl = '/api/instance/{instanceId}/articles/{locale}/{slug}';

/*
   * Tips (aka articles) list component
   * Used on overview page for category and multi rater templates (for Tips widget)
   * Used on tips page for category and multi rater templates
 */
export const getArticles = async ({
    instanceId,
    waveId,
    locale,
    cat,
}: ArticleListParams): Promise<CmsArticlesResponse> => {
    const category = cat.metaname;

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Category', category);

    const params: ArticleListPayload = {
        waveId,
        locale,
    };

    let url = getArticleListUrl;
    url = url.replace('{instanceId}', instanceId);
    url = url.replace('{category}', category);

    return httpClient.get(
        url,
        { params },
    )
        .then((bffResponse: CmsArticlesResponse) => {
            const enrichedArticles = bffResponse.data.articles.map((article) => ({
                ...article,
                categoryLabel: cat.title,
            }));
            return {
                ...bffResponse,
                data: {
                    articles: enrichedArticles,
                },
            };
        });
};

/*
   * Tip (aka article) component
   * Used on tip page for category and multi rater templates
 */
export const getArticle = async ({
    instanceId,
    waveId,
    fullSlug,
}: ArticleParams): Promise<CmsArticleResponse> => {
    validateEmpty('fullSlug', fullSlug);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Instance-ID', instanceId);

    let slug = fullSlug;
    const slugParts = fullSlug.split('/');
    let urlLang = '';
    if (slugParts.length === 3) {
        urlLang = slugParts[0].replace('-', '_');
        slug = fullSlug.substring(fullSlug.indexOf(slugParts[2]));
    }

    const params = { waveId };

    let url = getArticleUrl;
    url = url.replace('{instanceId}', instanceId);
    url = url.replace('{slug}', slug);
    url = url.replace('{locale}', urlLang);

    return httpClient.get(
        url,
        { params },
    )
        .then((response: CmsArticleResponse) => response);
};
