import Vue from 'vue';
import { ActionContext } from 'vuex';
import { CategoriesStoreState } from '@/interfaces/stores';
import { CategoryRaterGroupMetric, CategoryScoreMetric } from '@/interfaces/visual';
import {
    BffCategory,
    BffCategoryFavorable,
} from '@/interfaces/bff';
import { CategoryListParams, CategoryRaterGroupsParams } from '@/interfaces/services';
import {
    allArea,
    devArea,
    measureFav,
    strengthArea,
} from '@/constants';
import {
    getCategoriesDV,
    getCategoriesAggregatedDV,
    getCategoryScoreForRaterGroupsDV,
} from '@/services/dashboardServiceDV';
import {
    getCategories,
    getCategoriesAggregated,
    getCategoryScoreForRaterGroups,
} from '@/services/categoryService';
import {
    mapToCategoryScoreMetric,
    mapToCategoryScoreMetricFavorable,
    mapToCategoryRaterGroupsMetric,
} from '@/services/ModelMapperService';
import { inIframe } from '@/services/browserApiService';
import { showErrorNotification } from '@/store/articles';

export const getDefaultStateCategories = (): CategoriesStoreState => ({
    categoriesUnfiltered: [],
    categories: [],
    groupsFulfillingAnonymity: [],
    selectedCategoryKey: '',
    raterGroupsScores: [],
    creatingActionListener: false,
    requestInFlight: null,
});

const categoriesStore = {
    namespaced: true,
    state: getDefaultStateCategories(),
    getters: {
        devCategories(state: CategoriesStoreState): CategoryScoreMetric[] {
            return state.categories.filter((category) => category.area === devArea);
        },
        strengthCategories(state: CategoriesStoreState): CategoryScoreMetric[] {
            return state.categories.filter((category) => category.area === strengthArea);
        },
        devCategoriesUnfiltered(state: CategoriesStoreState): CategoryScoreMetric[] {
            return state.categoriesUnfiltered.filter((category) => category.area === devArea);
        },
        strengthCategoriesUnfiltered(state: CategoriesStoreState): CategoryScoreMetric[] {
            return state.categoriesUnfiltered.filter((category) => category.area === strengthArea);
        },
        selectedCategory(state: CategoriesStoreState): CategoryScoreMetric {
            return state.categories.filter((category) => category.key === state.selectedCategoryKey)[0];
        },
        devRaterGroupsScores(state: CategoriesStoreState): CategoryRaterGroupMetric[] {
            return state.raterGroupsScores.filter((raterGroupScore) => raterGroupScore.area === devArea);
        },
        strengthRaterGroupsScores(state: CategoriesStoreState): CategoryRaterGroupMetric[] {
            return state.raterGroupsScores.filter((raterGroupScore) => raterGroupScore.area === strengthArea);
        },
        minScale(state: CategoriesStoreState): number {
            return state.categoriesUnfiltered.reduce((acc, cat) => (acc < cat.minScore ? acc : cat.minScore), 0);
        },
        maxScale(state: CategoriesStoreState): number {
            return state.categoriesUnfiltered.reduce((acc, cat) => (acc > cat.maxScore ? acc : cat.maxScore), 0);
        },
    },
    mutations: {
        resetState(state: CategoriesStoreState): void {
            Object.assign(state, getDefaultStateCategories());
        },
        setCategories(state: CategoriesStoreState, categories: CategoryScoreMetric[]): void {
            state.categories = categories;
        },
        setCategoriesUnfiltered(state: CategoriesStoreState, categories: CategoryScoreMetric[]): void {
            state.categoriesUnfiltered = categories;
        },
        setCategoryKey(state: CategoriesStoreState, categoryKey: string): void {
            state.selectedCategoryKey = categoryKey;
        },
        setRaterGroupsScores(state: CategoriesStoreState, raterGroupsScores: CategoryRaterGroupMetric[]): void {
            state.raterGroupsScores = raterGroupsScores;
        },
        setGroupsFulfillingAnonymity(state: CategoriesStoreState, groups: string[]): void {
            state.groupsFulfillingAnonymity = groups;
        },
        setRequestInFlight(state: CategoriesStoreState, requestInFlight): void {
            state.requestInFlight = requestInFlight;
        },
    },
    actions: {
        async loadCategories({
            commit,
            dispatch,
            rootState,
            rootGetters,
            state,
        }: ActionContext<CategoriesStoreState, any>): Promise<any> {
            if (state.requestInFlight) {
                return state.requestInFlight;
            }

            const isMultiRatersTemplate = rootGetters['instance/isMultiRatersTemplate'];
            const area = isMultiRatersTemplate ? rootState.multiRater.area : undefined;
            const isMultiCategoryTemplate = rootGetters['instance/isMultiCategoryTemplate'];
            const isDvDashboard = rootGetters['instance/isDVDashboard'];

            const params: CategoryListParams = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                locale: rootState.instance.locale,
                measure: rootState.instance.mainMeasure,
                area,
                raterGroup: rootState.multiRater.selectedRaterGroupMetaname,
            };

            let request;

            if (isDvDashboard) {
                request = getCategoriesDV(
                    params.instanceId,
                    params.waveId,
                    params.locale,
                    rootState.instance.feedbackConstruct,
                    params.measure,
                    area,
                    params.raterGroup,
                );
            } else {
                request = getCategories(params);
            }

            commit('setRequestInFlight', request);

            return request
                .then((res) => {
                    commit('setRequestInFlight', null);
                    const dashboardData = res.data;

                    const bffCategories = dashboardData.categories as any[];

                    if (dashboardData.groupsFulfillingAnonymity) {
                        commit('setGroupsFulfillingAnonymity', dashboardData.groupsFulfillingAnonymity);
                    }

                    let categories: CategoryScoreMetric[];

                    if (params.measure === measureFav) {
                        categories = bffCategories
                            .map((question: BffCategoryFavorable) => mapToCategoryScoreMetricFavorable(question, isDvDashboard));
                    } else {
                        categories = bffCategories
                            .map((question: BffCategory) => mapToCategoryScoreMetric(question, isDvDashboard));
                    }

                    if (isMultiCategoryTemplate
                    || (isMultiRatersTemplate && area === allArea)
                    ) {
                        commit('setCategoriesUnfiltered', categories);
                    }

                    commit('setCategories', categories);
                }).catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },

        async loadCategoriesAggregated({
            commit,
            dispatch,
            rootState,
            rootGetters,
        }: ActionContext<CategoriesStoreState, any>): Promise<any> {
            const { area } = rootState.multiRater;
            const isDvDashboard = rootGetters['instance/isDVDashboard'];

            const params = {
                instanceId: rootState.instance.instanceId,
                guideId: rootState.instance.guideId,
                locale: rootState.instance.locale,
                area,
            };

            let request;

            if (isDvDashboard) {
                request = getCategoriesAggregatedDV(
                    params.instanceId,
                    params.guideId,
                    params.locale,
                    rootState.instance.feedbackConstruct,
                    area,
                );
            } else {
                request = getCategoriesAggregated(params);
            }

            return request
                .then((res) => {
                    const dashboardData = res.data;

                    const bffCategories = dashboardData.categories as any[];

                    if (dashboardData.groupsFulfillingAnonymity) {
                        commit('setGroupsFulfillingAnonymity', dashboardData.groupsFulfillingAnonymity);
                    }

                    const categories: CategoryScoreMetric[] = bffCategories
                        .map((question: BffCategory) => mapToCategoryScoreMetric(question, isDvDashboard));

                    if (area === allArea) {
                        commit('setCategoriesUnfiltered', categories);
                    }

                    commit('setCategories', categories);
                }).catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },

        async loadRaterGroupsScores({
            commit,
            dispatch,
            rootState,
            rootGetters,
        }: ActionContext<CategoriesStoreState, any>): Promise<any> {
            const params: CategoryRaterGroupsParams = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                categoryIdFilter: rootState.categories.selectedCategoryKey,
            };

            let request;

            if (rootGetters['instance/isDVDashboard']) {
                request = getCategoryScoreForRaterGroupsDV(
                    params.instanceId,
                    params.waveId,
                    rootState.instance.feedbackConstruct,
                    params.categoryIdFilter,
                );
            } else {
                request = getCategoryScoreForRaterGroups(params);
            }

            return request
                .then((res) => {
                    const dashboardData = res.data;

                    const bffRaterGroups = dashboardData.raterGroups as any[];
                    const sanitizedGroups = bffRaterGroups

                        .filter((bffGroup) => rootState.multiRater.raterGroups.filter((group) => bffGroup.metaname === group.metaname).length);
                    const raterGroupsScores = sanitizedGroups
                        .map((group) => mapToCategoryRaterGroupsMetric(
                            group,
                            rootState.multiRater.raterGroups,
                        ));

                    commit('setRaterGroupsScores', raterGroupsScores);
                }).catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },

        receivedActionMessage(
            _,
            event: Record<string, any>,
        ): void {
            const { data } = event;
            if (data?.action === 'externalImprovementsUpdate') {
                const { payload } = data;
                const { action } = payload;

                if (action === 'actionBoardMissing') {
                    const { sendActionData } = payload;
                    if (sendActionData?.additionalData?.categoryKey) {
                        showErrorNotification(Vue, sendActionData.additionalData.categoryKey);
                    }
                }
            }
        },

        addCategoryAction({ state, dispatch }: ActionContext<CategoriesStoreState, any>, category: CategoryScoreMetric): void {
            const eventSource = inIframe() ? window.top : window.self;
            const receivedMessage = (event: Record<string, any>) => {
                dispatch('receivedActionMessage', event);
            };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            eventSource.postMessage({
                action: 'createImprovement',
                payload: {
                    categories: category.metaname,
                    sendActionData: {
                        additionalData: {
                            categoryKey: category.metaname,
                            showBoardLink: true,
                        },
                    },
                },
            }, '*');

            if (!state.creatingActionListener) {
                window.addEventListener('message', receivedMessage, false);
                state.creatingActionListener = true;
            }
        },
    },
};

export default categoriesStore;
