import { ActionContext } from 'vuex';
import { PortalCommunicationState } from '@/interfaces/stores';
import { inIframe } from '@/services/browserApiService';

export const getDefaultStatePortalCommunication = ():PortalCommunicationState => ({
    filterBarLocked: false,
});

const portalCommunication = {
    namespaced: true,
    state: getDefaultStatePortalCommunication(),
    mutations: {
        resetState(state: PortalCommunicationState): void {
            Object.assign(state, getDefaultStatePortalCommunication());
        },
        setFilterBarLocked(state: PortalCommunicationState, locked: boolean): void {
            state.filterBarLocked = locked;
        },
    },
    actions: {
        setPortalFilterBarLockStatus({ commit }: ActionContext<PortalCommunicationState, any>, locked: boolean): void {
            const eventSource = inIframe() ? window.top : window.self;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            eventSource.postMessage({
                action: 'setDashboFilterBarLockStatus',
                payload: {
                    locked,
                },
            }, '*');
            commit('setFilterBarLocked', locked);
        },
    },
};

export default portalCommunication;
