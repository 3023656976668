import { ActionContext } from 'vuex';
import { KpiState } from '@/interfaces/stores';
import { PrimaryMetricScore } from '@/interfaces/visual';
import {
    BffKpiFavorableResponseData,
    BffKpiResponseData,
} from '@/interfaces/bff';
import { KPIParams } from '@/interfaces/services';
import { getKpiDV } from '@/services/dashboardServiceDV';
import { getKpi } from '@/services/kpiService';
import {
    getPrimaryMetricStatistic,
    getPrimaryMetricStatisticFavorable,
} from '@/services/ModelMapperService';
import {
    measureFav,
} from '@/constants';

export const getDefaultStateKpi = (): KpiState => ({
    kpi: null,
    constructLabel: '',
});

const kpiStore = {
    namespaced: true,
    state: getDefaultStateKpi(),
    mutations: {
        resetState(state: KpiState): void {
            Object.assign(state, getDefaultStateKpi());
        },
        setKpi(state: KpiState, kpi: PrimaryMetricScore): void {
            state.kpi = kpi;
        },
        setConstructLabel(state: KpiState, constructLabel: string): void {
            state.constructLabel = constructLabel;
        },
    },
    actions: {
        async loadKpi({
            commit,
            dispatch,
            rootState,
            rootGetters,
        }: ActionContext<KpiState, any>): Promise<any> {
            const params: KPIParams = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                locale: rootState.instance.locale,
                measure: rootState.instance.mainMeasure,
            };

            let request;

            if (rootGetters['instance/isDVDashboard']) {
                request = getKpiDV(
                    params.instanceId,
                    params.waveId,
                    params.locale,
                    rootState.instance.kpiConstruct || rootState.instance.feedbackConstruct,
                    params.measure,
                );
            } else {
                request = getKpi(params);
            }

            return request.then((res) => {
                const dashboardData = res.data;

                let primaryMetricScore: PrimaryMetricScore | null;
                if (params.measure === measureFav) {
                    const { kpi } = dashboardData as BffKpiFavorableResponseData;
                    primaryMetricScore = getPrimaryMetricStatisticFavorable(kpi);
                } else {
                    const { kpi } = dashboardData as BffKpiResponseData;
                    primaryMetricScore = getPrimaryMetricStatistic(kpi);
                }

                commit('setKpi', primaryMetricScore);
                commit('setConstructLabel', dashboardData.constructLabel || '');
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },
    },
};

export default kpiStore;
