
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GuidedTourLinkInterface } from '@/interfaces/components';

@Component({})
export default class GuidedTourLink extends Vue implements GuidedTourLinkInterface {
    @Prop({ default: true }) isLoading!: boolean;

    emitStartTour(): void {
        this.$emit('startTour');
    }
}
