import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import store from '@/store';

const Inventory = () => import(/* webpackChunkName: "inventory" */ '../views/Inventory.vue');

const OperateurDAscenseur = () => import(/* webpackChunkName: "operateur-dascenseur" */ '../views/OperateurDAscenseur.vue');

const Article = () => import(/* webpackChunkName: "article" */ '../views/Article.vue');

const QuestionsOnlyRoot = () => import(/* webpackChunkName: "questions-only" */ '../views/QuestionsOnly/QuestionsOnlyRoot.vue');

const QuestionsOnlyComments = () => import(/* webpackChunkName: "questions-only" */ '../views/QuestionsOnly/QuestionsOnlyComments.vue');

const MultiCategoryRoot = () => import(/* webpackChunkName: "multi-category" */ '../views/MultiCategory/MultiCategoryRoot.vue');

const MultiCategoryScoredQuestions = () => import(/* webpackChunkName: "multi-category" */ '../views/MultiCategory/MultiCategoryScoredQuestions.vue');

const MultiCategoryComments = () => import(/* webpackChunkName: "multi-category" */ '../views/MultiCategory/MultiCategoryComments.vue');

const MultiCategoryArticles = () => import(/* webpackChunkName: "multi-category" */ '../views/MultiCategory/MultiCategoryArticles.vue');

const MultiCategoryHeatmap = () => import(/* webpackChunkName: "multi-category" */ '../views/MultiCategory/MultiCategoryHeatmap.vue');

const RaterGroupsRoot = () => import(/* webpackChunkName: "rater-groups" */ '../views/RaterGroups/RaterGroupsRoot.vue');

const RaterGroupsScoredCategories = () => import(/* webpackChunkName: "rater-groups" */ '../views/RaterGroups/RaterGroupsScoredCategories.vue');

const RaterGroupsScoredQuestions = () => import(/* webpackChunkName: "rater-groups" */ '../views/RaterGroups/RaterGroupsScoredQuestions.vue');

const RaterGroupsComments = () => import(/* webpackChunkName: "rater-groups" */ '../views/RaterGroups/RaterGroupsComments.vue');

const RaterGroupsArticles = () => import(/* webpackChunkName: "rater-groups" */ '../views/RaterGroups/RaterGroupsArticles.vue');

const RaterGroupsHeatmap = () => import(/* webpackChunkName: "rater-groups" */ '../views/RaterGroups/RaterGroupsHeatmap.vue');

const VibesRoot = () => import(/* webpackChunkName: "vibes" */ '../views/Vibes/VibesRoot.vue');

const AggregatedRoot = () => import(/* webpackChunkName: "aggregated" */ '../views/Aggregated/AggregatedRoot.vue');

const AggregatedCategories = () => import(/* webpackChunkName: "aggregated" */ '../views/Aggregated/AggregatedCategories.vue');

const AggregatedQuestions = () => import(/* webpackChunkName: "aggregated" */ '../views/Aggregated/AggregatedScoredQuestions.vue');

const ErrorView = () => import(/* webpackChunkName: "error" */ '../views/Error.vue');

const TemplateRoot = () => import(/* webpackChunkName: "template" */ '../views/Template/TemplateRoot.vue');

const SsoLogin = () => import(/* webpackChunkName: "sso" */ '../views/SsoLogin.vue');

Vue.use(VueRouter);

export const routes = [
    // Login callback route
    {
        path: '/',
        name: 'login',
        component: SsoLogin,
        props: false,
    },
    // Misc routes
    {
        path: '/inventory',
        name: 'inventory',
        component: Inventory,
        props: false,
    },
    {
        path: '/error',
        name: 'error',
        component: ErrorView,
        props: false,
    },
    // root routes first because of browser back button
    {
        path: '/:instanceId/questions-only',
        name: 'questions-only-root',
        component: QuestionsOnlyRoot,
        props: false,
    },
    {
        path: '/:instanceId/multi-category',
        name: 'multi-category-root',
        component: MultiCategoryRoot,
        props: false,
    },
    {
        path: '/:instanceId/rater-groups',
        name: 'rater-groups-root',
        component: RaterGroupsRoot,
        props: false,
    },
    {
        path: '/:instanceId/vibes',
        name: 'vibes-root',
        component: VibesRoot,
        props: false,
    },
    {
        path: '/:instanceId/aggregated',
        name: 'aggregated-root',
        component: AggregatedRoot,
        props: false,
    },
    {
        path: '/:instanceId/template*',
        name: 'template-root',
        component: TemplateRoot,
        props: false,
    },
    // operateur-dascenseur - route for initial navigation to decide which template aka root route to show
    {
        path: '/:instanceId',
        name: 'operateur-dascenseur',
        component: OperateurDAscenseur,
        props: false,
    },
    {
        path: '/:instanceId/:waveId',
        name: 'operateur-dascenseur-wave',
        component: OperateurDAscenseur,
        props: false,
    },

    // questions only
    {
        path: '/:instanceId/questions-only/comments',
        name: 'questions-only-comments',
        component: QuestionsOnlyComments,
        props: false,
    },

    // multi category
    {
        path: '/:instanceId/multi-category/questions/:category',
        name: 'multi-category-questions',
        component: MultiCategoryScoredQuestions,
        props: false,
    },
    {
        path: '/:instanceId/multi-category/comments',
        name: 'multi-category-comments',
        component: MultiCategoryComments,
        props: false,
    },
    {
        path: '/:instanceId/multi-category/articles',
        name: 'multi-category-articles',
        component: MultiCategoryArticles,
        props: false,
        meta: {
            portalFilterBarLocked: true,
        },
    },
    {
        path: '/:instanceId/multi-category/heatmap',
        name: 'multi-category-heatmap',
        component: MultiCategoryHeatmap,
        props: false,
    },

    // rater groups
    {
        path: '/:instanceId/rater-groups/questions/:category/:area',
        name: 'rater-groups-questions',
        component: RaterGroupsScoredQuestions,
        props: false,
    },
    {
        path: '/:instanceId/rater-groups/categories/:area',
        name: 'rater-groups-categories',
        component: RaterGroupsScoredCategories,
        props: false,
    },
    {
        path: '/:instanceId/rater-groups/comments',
        name: 'rater-groups-comments',
        component: RaterGroupsComments,
        props: false,
    },
    {
        path: '/:instanceId/rater-groups/articles',
        name: 'rater-groups-articles',
        component: RaterGroupsArticles,
        props: true,
        meta: {
            portalFilterBarLocked: true,
        },
    },
    {
        path: '/:instanceId/rater-groups/heatmap',
        name: 'rater-groups-heatmap',
        component: RaterGroupsHeatmap,
        props: false,
    },

    // aggregated
    {
        path: '/:instanceId/aggregated/categories/:area',
        name: 'aggregated-categories',
        component: AggregatedCategories,
        props: false,
    },
    {
        path: '/:instanceId/aggregated/:category/questions/',
        name: 'aggregated-questions',
        component: AggregatedQuestions,
        props: false,
    },

    // must be at the end
    {
        path: '/:instanceId/article/:templateRootRoute/:backRoute/:fullSlug',
        name: 'article',
        component: Article,
        props: true,
        meta: {
            portalFilterBarLocked: true,
        },
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach(async (to: Route, fromRoute, next: NavigationGuardNext) => {
    const { instanceId, waveId, guideId } = to.params;
    const { meta } = to;
    const instanceIdMatching = instanceId === store.state.instance.instanceId;
    const waveIdMatching = !waveId || waveId === store.state.instance.waveId;
    const guideIdMatching = !guideId || guideId === store.state.instance.guideId;
    const isPortalFilterBarLocked = store.state.portalCommunication.filterBarLocked;

    if (!instanceIdMatching || !waveIdMatching || !guideIdMatching) {
        await store.dispatch('resetState');
    }

    if (isPortalFilterBarLocked && !meta?.portalFilterBarLocked) {
        store.dispatch('portalCommunication/setPortalFilterBarLockStatus', false);
    }
    if (!isPortalFilterBarLocked && meta && meta.portalFilterBarLocked) {
        store.dispatch('portalCommunication/setPortalFilterBarLockStatus', true);
    }

    next();
});

router.afterEach(async () => {
    if (window.parentIFrame) {
        window.parentIFrame.scrollTo(0, 0);
    }
});

export default router;
