import { ActionContext } from 'vuex';
import i18nLocalization from '@/i18n/i18nLocalization';
import { ResponseRatesState } from '@/interfaces/stores';
import { SimpleMetric } from '@/interfaces/visual';
import { BaseParams } from '@/interfaces/services';
import { getResponseRateDV } from '@/services/dashboardServiceDV';
import { getResponseRate } from '@/services/responseRateService';

export const getDefaultStateResponseRates = (): ResponseRatesState => ({
    responseRates: [],
    requestInFlight: null,
});

const responseRatesStore = {
    namespaced: true,
    state: getDefaultStateResponseRates(),
    mutations: {
        resetState(state: ResponseRatesState): void {
            Object.assign(state, getDefaultStateResponseRates());
        },
        setResponseRates(state: ResponseRatesState, responseRates: SimpleMetric[]): void {
            state.responseRates = responseRates;
        },
        setRequestInFlight(state: ResponseRatesState, requestInFlight): void {
            state.requestInFlight = requestInFlight;
        },
    },
    actions: {
        async loadResponseRates({
            commit,
            dispatch,
            rootState,
            rootGetters,
            state,
        }: ActionContext<ResponseRatesState, any>): Promise<any> {
            if (state.requestInFlight) {
                return state.requestInFlight;
            }

            const params: BaseParams = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
            };

            let request;

            if (rootGetters['instance/isDVDashboard']) {
                request = getResponseRateDV(
                    params.instanceId,
                    params.waveId,
                    rootState.instance.feedbackConstruct,
                );
            } else {
                request = getResponseRate(params);
            }

            commit('setRequestInFlight', request);

            return request.then((res) => {
                commit('setRequestInFlight', null);
                const responseRates: SimpleMetric[] = [];
                const { data } = res;
                let percentValue = 0;

                const openParticipation: SimpleMetric = {
                    label: 'openParticipation',
                    value: data.openParticipation,
                };
                responseRates.push(openParticipation);

                if (data.totalParticipants && data.totalParticipants > 0) {
                    percentValue = Math.round((data.totalAnswered * 100) / data.totalParticipants);
                }

                const percent: SimpleMetric = {
                    label: '%',
                    value: percentValue,
                };
                responseRates.push(percent);

                const totalParticipants: SimpleMetric = {
                    label: i18nLocalization.t('invited').toString(),
                    value: data.totalParticipants,
                };
                responseRates.push(totalParticipants);

                const totalResponses: SimpleMetric = {
                    label: i18nLocalization.t('responses').toString(),
                    value: data.totalAnswered,
                };
                responseRates.push(totalResponses);

                const noResponses: SimpleMetric = {
                    label: i18nLocalization.t('noResponses').toString(),
                    value: (data.totalParticipants - data.totalAnswered),
                };
                responseRates.push(noResponses);

                commit('setResponseRates', responseRates);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },
    },
};

export default responseRatesStore;
