import Vue from 'vue';
import VueI18n from 'vue-i18n';

import messagesEn from '@/i18n/en.json';
import messagesDe from '@/i18n/de.json';
import messagesEs from '@/i18n/es.json';
import messagesFr from '@/i18n/fr.json';

Vue.use(VueI18n);

const i18nLocalization = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: messagesEn,
        de: messagesDe,
        es: messagesEs,
        fr: messagesFr,
    },
});

export default i18nLocalization;
