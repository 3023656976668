const primary = {
    lighten1: '#EDE7F6',
    base: '#673AB7',
    darken1: '#58329B',
};

const secondary = {
    lighten1: '#E0F3F4',
    base: '#00ACAD',
    darken1: '#00807C',
};

const decorative1 = '#B73A89';
const decorative2 = '#3A4BB7';

const textPrimary = '#212B36';
const textSecondary = '#5E738A';

const criticalPrimary = '#D83543';
const criticalSecondary = '#FACAD1';

const warningPrimary = '#E3A12B';
const warningSecondary = '#FFF2DA';

const informativePrimary = '#673AB7';
const informativeSecondary = '#D6C8F3';

const successPrimary = '#208234';
const successSecondary = '#EBFFED';

const neutralShade = {
    lighten5: '#F4F6F8',
    lighten4: '#CCD5DE',
    lighten3: '#ADB9C6',
    lighten2: '#8D9DAE',
    lighten1: '#76879B',
    base: '#5E738A',
    darken1: '#516579',
    darken2: '#425263',
    darken3: '#333F4D',
    darken4: '#212B36',
};

const primaryShade = {
    lighten5: '#EDE7F6', // 50
    lighten4: '#D1C4E9', // 100
    lighten3: '#B39DDB', // 200
    lighten2: '#9675CD', // 300
    lighten1: '#7F57C2', // 400
    base: '#673AB7', // 500
    darken1: '#5F35B1', // 600
    darken2: '#522DA8', // 700
    darken3: '#4627A0', // 800
    darken4: '#321B92', // 900
};

export default {
    primary,
    secondary,

    textPrimary,
    textSecondary,

    decorative1,
    decorative2,

    criticalPrimary,
    criticalSecondary,

    warningPrimary,
    warningSecondary,

    informativePrimary,
    informativeSecondary,

    successPrimary,
    successSecondary,

    primaryShade,

    neutralShade,
    grey: neutralShade,
};
