import cloneDeep from 'clone-deep';
import {
    actionStatusNone,
    actionStatusTakeAction,
    actionStatusSeeAction,
} from '@/constants';
import {
    BffQuestionsFavorableResponse,
    BffQuestionsFavorableResponseData, BffQuestionsResponse,
    BffQuestionsResponseData,
    BffQuestionsResponseQuestion,
    BffQuestionsResponseQuestionFavorable
} from '@/interfaces/bff';
import { constructLabel, scaleTextList } from '@/fixtures';

export const scoredQuestions: BffQuestionsResponseQuestion[] = [
    {
        id: 592,
        text: 'If I make a mistake in our team, it is not held against me.',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_1',
        data: {
            mean: 3.25,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 4,
                missing: 5,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 3.25,
            median: 2,
            standardDeviation: 3,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 3,
                    percentageOfPeople: 0.75,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 6,
                    label: scaleTextList[5],
                    numberOfPeople: 5,
                    type: 'missing',
                },
            ],
        },
    },
    {
        id: 593,
        text: 'Members of this team are able to bring up problems and tough issues.',
        measurementLevel: null,
        anonymous: false,
        externalVariableName: 'v_2',
        data: {
            mean: 1.25,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 4,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 1,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 2,
                    percentageOfPeople: 0.5,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
            ],
        },
    },
    {
        id: 594,
        text: 'It is safe to take a risk on this team.',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_3',
        data: {
            mean: 2,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 4,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 3,
            median: 2,
            standardDeviation: 3,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 2,
                    percentageOfPeople: 0.5,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
            ],
        },
    },
    {
        id: 595,
        text: 'It is difficult to ask other members of this team for help.',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_4',
        data: {
            mean: 3,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 4,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 2,
            median: 1,
            standardDeviation: 1,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 2,
                    percentageOfPeople: 0.5,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 2,
                    percentageOfPeople: 0.5,
                    type: 'default',
                },
            ],
        },
    },
    {
        id: 596,
        text: 'I can\'t wait to see my awesome colleagues again.',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_5',
        data: {
            mean: 5,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 4,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 4,
            median: 2,
            standardDeviation: 3,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 4,
                    percentageOfPeople: 1,
                    type: 'default',
                },
            ],
        },
    },
    {
        id: 597,
        text: 'The cake is a lie!',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_6',
        data: {
            mean: 0,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 0,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 0,
            median: 2,
            standardDeviation: 1,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
            ],
        },
    },
    {
        id: 598,
        text: 'Test driven development is no good idea and too expensive!',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v7',
        data: {
            mean: 1,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 200,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 1,
            median: 2,
            standardDeviation: 3,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 190,
                    percentageOfPeople: 0.95,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 10,
                    percentageOfPeople: 0.05,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
            ],
        },
    },
    {
        id: 599,
        text: 'Where did you found hints to the position of the holy grail?',
        measurementLevel: 'default',
        anonymous: true,
        externalVariableName: 'v8',
        data: {
            mean: -1,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 0,
                missing: 5,
                notSeen: 0,
                skipped: 2,
            },
            lastMean: -1,
            median: -1,
            standardDeviation: -1,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 6,
                    label: scaleTextList[5],
                    numberOfPeople: 5,
                    type: 'missing',
                },
            ],
        },
    },
    {
        id: 600,
        text: 'From which brand did you buy shoes in the last 12 month?',
        measurementLevel: 'nominal',
        anonymous: false,
        externalVariableName: 'v9',
        data: {
            mean: -1,
            min: -1,
            max: -1,
            responseTypeStats: {
                answered: 53,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: -1,
            median: 2,
            standardDeviation: 3,
            stats: [
                {
                    code: 1,
                    label: 'Nikedsafasdsf',
                    numberOfPeople: 25,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
                {
                    code: 2,
                    label: 'Adidasadsfsdafds',
                    numberOfPeople: 10,
                    percentageOfPeople: 0.1,
                    type: 'default',
                },
                {
                    code: 3,
                    label: 'Fila',
                    numberOfPeople: 5,
                    percentageOfPeople: 0.05,
                    type: 'default',
                },
                {
                    code: 4,
                    label: 'Birkenstock',
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: 'Calvin Klein',
                    numberOfPeople: 1,
                    percentageOfPeople: 0.01,
                    type: 'default',
                },
                {
                    code: 6,
                    label: 'Hello Kitty',
                    numberOfPeople: 12,
                    percentageOfPeople: 0.12,
                    type: 'default',
                },
            ],
        },
    },
];

export const scoredQuestionsFavorable: BffQuestionsResponseQuestionFavorable[] = [
    {
        id: 592,
        text: 'If I make a mistake in our team, it is not held against me.',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_1',
        data: {
            responseTypeStats: {
                answered: 4,
                missing: 5,
                notSeen: 0,
                skipped: 0,
            },
            favorable: 0.78,
            lastFavorable: 0.89,
            median: 2,
            standardDeviation: 3,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 3,
                    percentageOfPeople: 0.75,
                    type: 'top',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'top',
                },
                {
                    code: 6,
                    label: scaleTextList[5],
                    numberOfPeople: 5,
                    type: 'missing',
                },
            ],
        },
    },
    {
        id: 593,
        text: 'Members of this team are able to bring up problems and tough issues.',
        measurementLevel: null,
        anonymous: false,
        externalVariableName: 'v_2',
        data: {
            responseTypeStats: {
                answered: 4,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            favorable: 0.89,
            lastFavorable: 0.78,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 1,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 3,
                    percentageOfPeople: 0.75,
                    type: 'top',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0.25,
                    type: 'top',
                },
            ],
        },
    },
    {
        id: 594,
        text: 'It is safe to take a risk on this team.',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_3',
        data: {
            responseTypeStats: {
                answered: 4,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            favorable: 0.35,
            lastFavorable: -1,
            median: 2,
            standardDeviation: 3,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0.75,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 3,
                    percentageOfPeople: 0,
                    type: 'top',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'top',
                },
            ],
        },
    },
    {
        id: 595,
        text: 'It is difficult to ask other members of this team for help.',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_4',
        data: {
            responseTypeStats: {
                answered: 4,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            favorable: 0.50,
            lastFavorable: 0.50,
            median: 2,
            standardDeviation: 3,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 1,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0.75,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 3,
                    percentageOfPeople: 0.25,
                    type: 'top',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'top',
                },
            ],
        },
    },
    {
        id: 596,
        text: 'I can\'t wait to see my awesome colleagues again.',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_5',
        data: {
            responseTypeStats: {
                answered: 4,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            favorable: 0.25,
            lastFavorable: 0.10,
            median: 3.3,
            standardDeviation: 1.2,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.10,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0.80,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0.10,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 3,
                    percentageOfPeople: 0,
                    type: 'top',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'top',
                },
            ],
        },
    },
    {
        id: 597,
        text: 'The cake is a lie!',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_6',
        data: {
            responseTypeStats: {
                answered: 0,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            favorable: 0,
            lastFavorable: 0.10,
            median: 3.3,
            standardDeviation: 1.2,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'top',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'top',
                },
            ],
        },
    },
    {
        id: 598,
        text: 'Test driven development is no good idea and too expensive!',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v7',
        data: {
            responseTypeStats: {
                answered: 4,
                missing: 0,
                notSeen: 0,
                skipped: 2,
            },
            favorable: 0.95,
            lastFavorable: 0.89,
            median: 3.3,
            standardDeviation: 1.2,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 3,
                    percentageOfPeople: 0.75,
                    type: 'top',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'top',
                },
            ],
        },
    },
    {
        id: 599,
        text: 'This question should not be answered!',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v8',
        data: {
            responseTypeStats: {
                answered: 4,
                missing: 5,
                notSeen: 0,
                skipped: 0,
            },
            favorable: 0,
            lastFavorable: -1,
            median: 3.3,
            standardDeviation: 1.2,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 1,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 3,
                    percentageOfPeople: 0,
                    type: 'top',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'top',
                },
                {
                    code: 6,
                    label: scaleTextList[5],
                    numberOfPeople: 5,
                    type: 'missing',
                }
            ],
        },
    },
];

export const scoredQuestionsWithSelfPerception: BffQuestionsResponseQuestion[] = [
    {
        id: 592,
        text: 'If I make a mistake in our team, it is not held against me.',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_1',
        data: {
            mean: 3.25,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 4,
                missing: 5,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 3.25,
            selfPerception: 4,
            median: 2,
            standardDeviation: 3,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 3,
                    percentageOfPeople: 0.75,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 6,
                    label: scaleTextList[5],
                    numberOfPeople: 5,
                    type: 'missing',
                },
            ],
        },
    },
    {
        id: 593,
        text: 'Members of this team are able to bring up problems and tough issues.',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_2',
        data: {
            mean: 1.25,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 4,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 1,
            selfPerception: 1,
            median: 3.3,
            standardDeviation: 1.2,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 2,
                    percentageOfPeople: 0.5,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
            ],
        },
    },
    {
        id: 594,
        text: 'It is safe to take a risk on this team.',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_3',
        data: {
            mean: 2,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 4,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 3,
            selfPerception: 2,
            median: 3.3,
            standardDeviation: 1.2,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 2,
                    percentageOfPeople: 0.5,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 1,
                    percentageOfPeople: 0.25,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
            ],
        },
    },
    {
        id: 595,
        text: 'It is difficult to ask other members of this team for help.',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_4',
        data: {
            mean: 3,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 4,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 2,
            selfPerception: 5,
            median: 3.3,
            standardDeviation: 1.2,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 2,
                    percentageOfPeople: 0.5,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 2,
                    percentageOfPeople: 0.5,
                    type: 'default',
                },
            ],
        },
    },
    {
        id: 596,
        text: 'I can\'t wait to see my awesome colleagues again.',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_5',
        data: {
            mean: 5,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 4,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 4,
            selfPerception: 4,
            median: 3.3,
            standardDeviation: 1.2,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 4,
                    percentageOfPeople: 1,
                    type: 'default',
                },
            ],
        },
    },
    {
        id: 597,
        text: 'The cake is a lie!',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v_6',
        data: {
            mean: 0,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 0,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 0,
            selfPerception: 2,
            median: 3.3,
            standardDeviation: 1.2,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
            ],
        },
    },
    {
        id: 598,
        text: 'Test driven development is no good idea and too expensive!',
        measurementLevel: 'default',
        anonymous: false,
        externalVariableName: 'v7',
        data: {
            mean: 1,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 200,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: 1,
            selfPerception: 1,
            median: 3.3,
            standardDeviation: 1.2,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 190,
                    percentageOfPeople: 0.95,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 10,
                    percentageOfPeople: 0.05,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
            ],
        },
    },
    {
        id: 599,
        text: 'Where did you found hints to the position of the holy grail?',
        measurementLevel: 'default',
        anonymous: true,
        externalVariableName: 'v8',
        data: {
            mean: -1,
            min: 1,
            max: 5,
            responseTypeStats: {
                answered: 0,
                missing: 0,
                notSeen: 0,
                skipped: 0,
            },
            lastMean: -1,
            median: -1,
            standardDeviation: -1,
            stats: [
                {
                    code: 1,
                    label: scaleTextList[0],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 2,
                    label: scaleTextList[1],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 3,
                    label: scaleTextList[2],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 4,
                    label: scaleTextList[3],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 5,
                    label: scaleTextList[4],
                    numberOfPeople: 0,
                    percentageOfPeople: 0,
                    type: 'default',
                },
                {
                    code: 6,
                    label: scaleTextList[5],
                    numberOfPeople: 0,
                    type: 'missing',
                },
            ],
        },
    },
];

const scoredQuestionsClone = cloneDeep(scoredQuestions);
export const scoredQuestionsWithFilteredData: BffQuestionsResponseQuestion[] = scoredQuestionsClone.map((question) => {
    const newQuestion = question;
    newQuestion.dataFiltered = cloneDeep(newQuestion.data);
    newQuestion.data.mean += 1;
    if (newQuestion.data.mean > 5) {
        newQuestion.data.mean = 5;
    }
    newQuestion.data.median = newQuestion.data.median ? newQuestion.data.median + 1 : undefined;
    newQuestion.data.standardDeviation = newQuestion.data.standardDeviation ? newQuestion.data.standardDeviation + 1 : undefined;
    newQuestion.data.stats = newQuestion.data.stats.map((stat) => {
        stat.numberOfPeople += 1;
        return stat;
    });

    return newQuestion;
});

const scoredQuestionsFavorableClone = cloneDeep(scoredQuestionsFavorable);
export const scoredQuestionsFavorableWithUnfilteredData: BffQuestionsResponseQuestionFavorable[] = scoredQuestionsFavorableClone.map((question) => {
    const newQuestion = question;
    newQuestion.dataFiltered = cloneDeep(newQuestion.data);
    newQuestion.data.favorable += 0.1;
    if (newQuestion.data.favorable > 1) {
        newQuestion.data.favorable = 1;
    }
    newQuestion.data.median = newQuestion.data.median ? newQuestion.data.median + 1 : undefined;
    newQuestion.data.standardDeviation = newQuestion.data.standardDeviation ? newQuestion.data.standardDeviation + 1 : undefined;
    newQuestion.data.stats = newQuestion.data.stats.map((stat) => {
        stat.numberOfPeople += 1;
        return stat;
    });

    return newQuestion;
});

export const actionWithAdd = {
    actionStatus: actionStatusTakeAction,
    actionProperties: {
        recommendation: {
            id: 1,
            externalVarname: 'v_1',
            title: 'recommendation title',
            content: 'recommendation description',

        },
    },
};

export const actionWithSee = {
    actionStatus: actionStatusSeeAction,
    actionProperties: {
        actionId: 1,
        creatorId: 11,
    },
};

export const actionWithNone = {
    actionStatus: actionStatusNone,
};

export const scoredQuestionsResponseData: BffQuestionsResponseData = {
    constructLabel,
    locale: 'en_US',
    questions: scoredQuestions,
}

export const scoredQuestionsFavorableResponseData: BffQuestionsFavorableResponseData = {
    constructLabel,
    locale: 'en_US',
    questions: scoredQuestionsFavorable,
};

export const scoredQuestionsWithSelfPerceptionResponseData: BffQuestionsResponseData = {
    constructLabel,
    locale: 'en_US',
    questions: scoredQuestionsWithSelfPerception,
};

export const scoredQuestionsWithFilterResponseData: BffQuestionsResponseData = {
    constructLabel,
    locale: 'en_US',
    questions: scoredQuestionsWithFilteredData,
};

export const scoredQuestionsResponse: BffQuestionsResponse = {
    status: 200,
    data: scoredQuestionsResponseData,
};

export const scoredQuestionsFavorableResponse: BffQuestionsFavorableResponse = {
    status: 200,
    data: scoredQuestionsFavorableResponseData,
};

export const scoredQuestionsWithSelfPerceptionResponse: BffQuestionsResponse = {
    status: 200,
    data: scoredQuestionsWithSelfPerceptionResponseData,
};

export const scoredQuestionsWithFilterResponse: BffQuestionsResponse = {
    status: 200,
    data: scoredQuestionsWithFilterResponseData,
};
