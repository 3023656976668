import { ActionContext } from 'vuex';
import axios from 'axios';
import { CommentQuestionsState } from '@/interfaces/stores';
import { OpenQuestionMetric } from '@/interfaces/visual';
import { CommentListParams } from '@/interfaces/services';
import { getOpenQuestionsDV } from '@/services/dashboardServiceDV';
import { getOpenQuestions } from '@/services/commentQuestionService';
import { mapToOpenQuestionMetric } from '@/services/ModelMapperService';
import { cancelledErrorType } from '@/helpers/error';
import { raterGroupAll } from '@/constants';

export const getDefaultStateCommentQuestions = (): CommentQuestionsState => ({
    commentQuestionsUnfiltered: [],
    commentQuestions: [],
    cancelCommentRequest: null,
});

const commentQuestionsStore = {
    namespaced: true,
    state: getDefaultStateCommentQuestions(),
    getters: {
        totalCommentsUnfiltered(state: CommentQuestionsState): number {
            // eslint-disable-next-line max-len
            return state.commentQuestionsUnfiltered.reduce((prev: number, openQuestion: OpenQuestionMetric) => prev + openQuestion.openAnswers.length, 0);
        },
        totalComments(state: CommentQuestionsState): number {
            return state.commentQuestions.reduce((prev: number, openQuestion: OpenQuestionMetric) => prev + openQuestion.openAnswers.length, 0);
        },
        allCommentsBelowAnonymity(state: CommentQuestionsState): boolean {
            return state.commentQuestions.length > 0 && state.commentQuestions.every((question) => question.type === 'anonymity');
        },
        commentsRequestInProgress(state: CommentQuestionsState): boolean {
            return !!state.cancelCommentRequest?.cancel;
        },
    },
    mutations: {
        resetState(state: CommentQuestionsState): void {
            Object.assign(state, getDefaultStateCommentQuestions());
        },
        setCommentQuestionsUnfiltered(state: CommentQuestionsState, commentQuestions: OpenQuestionMetric[]): void {
            state.commentQuestionsUnfiltered = commentQuestions;
        },
        setCommentQuestions(state: CommentQuestionsState, commentQuestions: OpenQuestionMetric[]): void {
            state.commentQuestions = commentQuestions;
        },
        addCommentsRequest(state: CommentQuestionsState): void {
            state.cancelCommentRequest = axios.CancelToken.source();
        },
        resetCommentsRequest(state: CommentQuestionsState): void {
            state.cancelCommentRequest = null;
        },
        cancelCommentsRequest(state: CommentQuestionsState): void {
            if (state.cancelCommentRequest?.cancel) {
                state.cancelCommentRequest.cancel();
            }
            state.cancelCommentRequest = null;
        },
    },
    actions: {
        async loadCommentQuestions({
            commit,
            dispatch,
            rootState,
            state,
            rootGetters,
        }: ActionContext<CommentQuestionsState, any>): Promise<any> {
            commit('addCommentsRequest');

            const selectedRaterGroup = rootState.multiRater.selectedRaterGroupMetaname;

            const params: CommentListParams = {
                source: state.cancelCommentRequest,
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                category: rootState.instance.feedbackConstruct,
                locale: rootState.instance.locale,
                selectedFilterScales: rootState.surveyFilters.selectedFilterScales,
                raterGroup: selectedRaterGroup,
            };

            let request;

            if (rootGetters['instance/isDVDashboard']) {
                request = getOpenQuestionsDV(
                    params.source,
                    params.instanceId,
                    params.waveId,
                    params.category,
                    params.locale,
                    params.selectedFilterScales,
                    params.raterGroup,
                );
            } else {
                request = getOpenQuestions(params);
            }

            return request.then((res) => {
                const isMultiRatersTemplate = rootGetters['instance/isMultiRatersTemplate'];
                const dashboardData = res.data;
                const commentQuestions = dashboardData.questions.map((question) => mapToOpenQuestionMetric(question));

                if (isMultiRatersTemplate && selectedRaterGroup === raterGroupAll) {
                    commit('setCommentQuestionsUnfiltered', commentQuestions);
                }

                commit('setCommentQuestions', commentQuestions);
                commit('resetCommentsRequest');
            }).catch((error: Error) => {
                if (error.name !== cancelledErrorType) {
                    dispatch('errorStore/errorReceived', error, { root: true });
                }
            });
        },
    },
};

export default commentQuestionsStore;
