import Vue from 'vue';
import { ActionContext } from 'vuex';
import { ArticlesState } from '@/interfaces/stores';
import { CmsArticle, CmsArticlesResponse } from '@/interfaces/cms';
import { ArticleListParams, ArticleParams } from '@/interfaces/services';
import { OptionItem } from '@/interfaces/visual';
import { inIframe } from '@/services/browserApiService';
import { getArticles, getArticle } from '@/services/articleService';

import i18nLocalization from '@/i18n/i18nLocalization';
import { selectAllKeys } from '@/constants';

export const getDefaultStateArticles = (): ArticlesState => ({
    articles: [],
    articlesForStrength: [],
    categoryFilter: [],
    articlesRequested: false,
    creatingActionListener: false,
});

export function articleFilter(articleList: CmsArticle[], filterList: string[]): CmsArticle[] {
    if (filterList.length > 0) {
        return articleList
            .filter((article: CmsArticle) => filterList.includes(article.content.category));
    }
    return articleList;
}

export const showErrorNotification = (globalVue, articleId: string): void => {
    globalVue.notify({
        group: 'global',
        text: i18nLocalization.t('actionError') as string,
        type: 'error',
        duration: 8000,
    });
    const btnWrapperEl = document.querySelector(`[data-id="${articleId}"]`);
    if (btnWrapperEl) {
        Vue.nextTick(() => {
            const notificationEl = document.querySelector('.vue-notification-group');
            if (notificationEl) {
                btnWrapperEl.append(notificationEl);
            }
        });
    }
};

const articlesStore = {
    namespaced: true,
    state: getDefaultStateArticles(),
    getters: {
        totalArticles: (state: ArticlesState): number => state.articles.length + state.articlesForStrength.length,
        getAllArticles: (state: ArticlesState): CmsArticle[] => ([...state.articles, ...state.articlesForStrength]),
        getCategoryFilter: (state: ArticlesState): string[] => state.categoryFilter,

        getFilteredArticles: (state: ArticlesState): CmsArticle[] => articleFilter(state.articles, state.categoryFilter),
        getFilteredArticlesForStrength: (state: ArticlesState): CmsArticle[] => (
            articleFilter(state.articlesForStrength, state.categoryFilter)
        ),
        getArticlesForCat: (state: ArticlesState, getters): (cat: string) => CmsArticle[] => (cat: string) => {
            const allArticles = getters.getAllArticles;
            return articleFilter(allArticles, [cat]);
        },
        mapArticleCategories: (state: ArticlesState, getters): OptionItem[] => {
            const allArticles = getters.getAllArticles;

            const uniqueCategories = {};
            allArticles.forEach((item) => {
                if (!uniqueCategories[item.content.category]) {
                    uniqueCategories[item.content.category] = item.categoryLabel;
                }
            });

            const categories: OptionItem[] = [
                {
                    metaname: selectAllKeys,
                    label: i18nLocalization.t('all') as string,
                },
            ];

            Object.entries(uniqueCategories).forEach(([metaname, label]) => {
                categories.push({
                    metaname,
                    label,
                } as OptionItem);
            });

            return categories;
        },
    },
    mutations: {
        resetState(state: ArticlesState): void {
            Object.assign(state, getDefaultStateArticles());
        },
        setCategoryFilter(state: ArticlesState, filter: string[]) {
            if (filter.includes(selectAllKeys)) {
                state.categoryFilter = [];
            } else {
                state.categoryFilter = filter;
            }
        },
        setArticles(state: ArticlesState, articles: CmsArticle[]): void {
            state.articles = articles;
        },
        setArticlesForStrength(state: ArticlesState, articles: CmsArticle[]): void {
            state.articlesForStrength = articles;
        },
        setArticlesRequested(state: ArticlesState, value: boolean): void {
            state.articlesRequested = value;
        },
    },
    actions: {
        async loadArticles({
            commit,
            rootState,
        }: ActionContext<ArticlesState, any>): Promise<any> {
            const articles: CmsArticle[] = [];
            const articlesForStrength: CmsArticle[] = [];
            const requests: Promise<CmsArticlesResponse>[] = [];
            const requestsForStrength: Promise<CmsArticlesResponse>[] = [];

            const devCats: string[] = [];
            const strengthCats: string[] = [];

            const { instanceId, waveId, locale } = rootState.instance;

            try {
                rootState.categories.categoriesUnfiltered.forEach((cat) => {
                    const params: ArticleListParams = {
                        instanceId,
                        waveId,
                        locale,
                        cat,
                    };

                    if (cat.metaname) {
                        // eslint-disable-next-line default-case
                        switch (cat.area) {
                            case 'dev': {
                                if (!devCats.includes(cat.metaname)) {
                                    requests.push(
                                        getArticles(params),
                                    );
                                    devCats.push(cat.metaname);
                                }
                                break;
                            }
                            case 'strength': {
                                if (!strengthCats.includes(cat.metaname)) {
                                    requestsForStrength.push(
                                        getArticles(params),
                                    );
                                    strengthCats.push(cat.metaname);
                                }
                                break;
                            }
                        }
                    }
                });
                const results = await Promise.all(requests);
                results.forEach((result) => {
                    articles.push(...result.data.articles);
                });

                const resultsForStrength = await Promise.all(requestsForStrength);
                resultsForStrength.forEach((result) => {
                    articlesForStrength.push(...result.data.articles);
                });

                commit('setArticles', articles);
                commit('setArticlesForStrength', articlesForStrength);
                commit('setArticlesRequested', true);
            } catch (e) {
                // do nothing
            }
        },

        async loadArticle(
            { dispatch, rootState }: ActionContext<ArticlesState, any>,
            fullSlug: string,
        ): Promise<any> {
            const params: ArticleParams = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                fullSlug,
            };

            return getArticle(params)
                .then()
                .catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },

        receivedActionMessage(
            _,
            event: Record<string, any>,
        ): void {
            const { data } = event;
            if (data?.action === 'externalImprovementsUpdate') {
                const { payload } = data;
                const { action } = payload;

                if (action === 'actionBoardMissing') {
                    const { sendActionData } = payload;
                    if (sendActionData?.additionalData?.articleId) {
                        showErrorNotification(Vue, sendActionData.additionalData.articleId);
                    }
                }
            }
        },

        addArticleAction({ state, dispatch }: ActionContext<ArticlesState, any>, article: CmsArticle): void {
            const eventSource = inIframe() ? window.top : window.self;
            const receivedMessage = (event: Record<string, any>) => {
                dispatch('receivedActionMessage', event);
            };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            eventSource.postMessage({
                action: 'createImprovement',
                payload: {
                    categories: article.content.category,
                    sendActionData: {
                        additionalData: {
                            articleId: article.uuid,
                            showBoardLink: true,
                        },
                    },
                },
            }, '*');

            if (!state.creatingActionListener) {
                window.addEventListener('message', receivedMessage, false);
                state.creatingActionListener = true;
            }
        },
    },
};

export default articlesStore;
