import httpClient from '@/clients/httpClient';
import { BffRaterGroupsResponse } from '@/interfaces/bff';
import { BaseParamsWithLocale } from '@/interfaces/services';
import { validateEmpty } from '@/services/utilities';

/* URLs */
export const getRaterGroupsUrl = '/api/instance/{instanceId}/ratergroups';

/*
   * Rater Group list
   * Used on every page for multi rater template
 */
export const getRaterGroups = async ({
    instanceId,
    waveId,
    locale,
}: BaseParamsWithLocale): Promise<BffRaterGroupsResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params = { waveId, locale };
    let url = getRaterGroupsUrl;
    url = url.replace('{instanceId}', instanceId);

    return httpClient.get(
        url,
        { params },
    )
        .then((response: BffRaterGroupsResponse) => response);
};
