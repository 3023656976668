import httpClient from '@/clients/httpClient';
import { BffKpiFavorableResponse, BffKpiResponse } from '@/interfaces/bff';
import { validateEmpty } from '@/services/utilities';
import { KPIParams, KPIPayload } from '@/interfaces/services';
import { measureMean } from '@/constants';

/* URLs */
export const getKPIUrl = '/api/instances/{instanceId}/kpi';

/*
   * KPI widget
   * Used on overview page for category and multi rater templates
 */
export const getKpi = async ({
    instanceId,
    waveId,
    locale,
    measure = measureMean,
}: KPIParams): Promise<BffKpiResponse | BffKpiFavorableResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params: KPIPayload = {
        waveId,
        locale,
        measure,
    };

    let url = getKPIUrl;
    url = url.replace('{instanceId}', instanceId);

    return httpClient.get(
        url,
        { params },
    )
        .then((response: BffKpiResponse | BffKpiFavorableResponse) => response);
};
