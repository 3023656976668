/* istanbul ignore file */
import { defaultBackgroundColor } from '@/constants';

export const inIframe = (): boolean => window.self !== window.top;

export const isIE = (): RegExpMatchArray | null => window.navigator.userAgent.match(/(MSIE|Trident)/);

export const isOldSafari = (): boolean => {
    const { userAgent } = window.navigator;

    const isSafari = userAgent.indexOf('Safari') > -1
        && userAgent.indexOf('Chrome') === -1
        && userAgent.indexOf('Chromium') === -1;

    if (isSafari) {
        const index = userAgent.indexOf('Version');
        /*  istanbul ignore else */
        if (index > -1) {
            const version = parseInt(userAgent.substr(index + 8, 1), 10);
            return version > 1 && version <= 9;
        }
    }
    return false;
};

export const isFullscreenActive = (): boolean => {
    const docWithBrowsersStateFunctions = document as Document & {
        webkitFullscreenElement: Element;
        mozFullScreenElement: Element;
        msFullscreenElement: Element;
    };
    if (docWithBrowsersStateFunctions.fullscreenElement) {
        return !!docWithBrowsersStateFunctions.fullscreenElement;
    }
    if (docWithBrowsersStateFunctions.webkitFullscreenElement) {
        return !!docWithBrowsersStateFunctions.webkitFullscreenElement;
    }
    if (docWithBrowsersStateFunctions.mozFullScreenElement) {
        return !!docWithBrowsersStateFunctions.mozFullScreenElement;
    }
    if (docWithBrowsersStateFunctions.msFullscreenElement) {
        return !!docWithBrowsersStateFunctions.msFullscreenElement;
    }
    return false;
};

export const openFullscreen = async (): Promise<any> => {
    // Trigger fullscreen
    const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
        mozRequestFullScreen(): Promise<void>;
        webkitRequestFullscreen(): Promise<void>;
        msRequestFullscreen(): Promise<void>;
    };

    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
        await docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
        await docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        await docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
        await docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }
};

export const closeFullscreen = async (): Promise<any> => {
    const docWithBrowsersExitFunctions = document as Document & {
        exitFullscreen(): Promise<void>;
        mozCancelFullScreen(): Promise<void>;
        webkitExitFullscreen(): Promise<void>;
        msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
        await docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
        await docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        await docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
        await docWithBrowsersExitFunctions.msExitFullscreen();
    }
};

const fullscreenchangeHandler = (): void => {
    if (isFullscreenActive()) {
        document.body.style.backgroundColor = defaultBackgroundColor;
    } else {
        document.body.style.backgroundColor = 'transparent';
    }
};

export const addFullscreenListener = (): void => {
    document.addEventListener('fullscreenchange', fullscreenchangeHandler);
};

export const removeFullscreenListener = (): void => {
    document.removeEventListener('fullscreenchange', fullscreenchangeHandler);
};
