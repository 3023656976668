
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { TabsInterface } from '@/interfaces/components';
import { OptionItem } from '@/interfaces/visual';

@Component({})
export default class Tabs extends Vue implements TabsInterface {
    @Prop({ default: [] }) tabs!: OptionItem[];
    @Prop({ default: 0 }) selected!: string;
    @Prop({ default: null }) linkTourKey!: string;

    emitInput(value: string): void {
        if (this.selected !== value) {
            this.$emit('input', value);
        }
    }
}
