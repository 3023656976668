import { BffInteractiveComment, BffInteractiveCommentsData, BffInteractiveCommentsResponse } from '@/interfaces/bff';


export const bffInteractiveComments: BffInteractiveComment[] = [
  {
    variableName: 'dummy',
    participationId: 0,
    createdAt: '2020-12-01T14:15Z',
    creator: {
      type: 'anonymous',
    },
    score: {
      value: 2,
      type: 'mid',
    },
    tags: [],
    reactions: [],
    comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nisi mi, accumsan vitae lacinia ut, viverra eget elit. Vivamus vel dapibus risus. Donec sed ipsum sed quam sodales molestie. Fusce commodo neque purus, non accumsan massa rutrum a. Ut volutpat lobortis mollis. Maecenas eu velit mauris. Nullam ac ultrices tortor. Maecenas ultricies ullamcorper ante eget vulputate. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec varius massa sapien, sodales viverra dolor tempor at. Curabitur sed ante vestibulum, consectetur massa eget, elementum mi. Aenean erat massa, eleifend a lobortis vel, bibendum a elit. Pellentesque mauris lorem, ultricies nec elit quis, varius ornare quam. Aliquam tempor id felis consequat facilisis. Morbi fermentum nibh ante, at placerat quam tincidunt eu. Nam non magna tortor.',
  },
  {
    variableName: 'dummy',
    participationId: 0,
    createdAt: '2020-11-27T11:42Z',
    creator: {
      type: 'anonymous',
    },
    score: {
      value: 2,
      type: 'mid',
    },
    tags: [],
    reactions: [],
    comment: 'Suspendisse ornare, dolor a semper feugiat, metus nisi egestas diam, vel mattis est sapien at enim. Curabitur a sodales purus, eu consequat metus. Mauris egestas interdum volutpat. Vestibulum ac urna nibh. Fusce et placerat lacus, vel porta justo. Morbi vitae magna eget mauris scelerisque dapibus vel ut massa. Vestibulum at quam eget mauris gravida ultrices id at est. Duis arcu felis, ullamcorper vel metus eu, maximus ultrices nunc. Integer cursus urna quis sagittis consequat. Nulla facilisi. Donec luctus tempus dolor, quis sodales odio pharetra consequat. Aliquam volutpat sagittis est, aliquam faucibus neque luctus in. Nunc tincidunt convallis egestas. Pellentesque ligula magna, mollis vitae tortor ut, tincidunt imperdiet libero. Etiam tincidunt urna non lorem varius tristique. Praesent non convallis mauris.',
  },
  {
    variableName: 'dummy',
    participationId: 0,
    createdAt: '2020-11-29T09:38Z',
    creator: {
      type: 'anonymous',
    },
    score: {
      value: 2,
      type: 'mid',
    },
    tags: [],
    reactions: [],
    comment: 'Cras sed lectus vel velit sagittis ullamcorper non a nunc. Sed eget purus ut mauris lobortis dapibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aliquam pulvinar ligula tortor, ac eleifend nisl bibendum vitae. Quisque eu justo nunc. Morbi luctus orci nisi, vel sollicitudin nulla porttitor quis. Suspendisse bibendum ornare condimentum. Praesent neque odio, elementum dapibus purus non, elementum aliquam libero. Cras egestas nunc eget vehicula euismod. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.',
  },
];

export const bffInteractiveCommentsData: BffInteractiveCommentsData = {
  comments: bffInteractiveComments,
  locale: 'en_US',
  constructLabel: 'Interactive Comments',
};

export const bffInteractiveCommentsResponse: BffInteractiveCommentsResponse = {
  status: 200,
  data: bffInteractiveCommentsData,
}
