import { AuthenticationStoreState } from '@/interfaces/stores';

export const getDefaultStateAuthentication = ():AuthenticationStoreState => ({
    loggedIn: false,
    invalidTokenRequests: {},
    invalidTokenRequestPass: false,
});

const authenticationStore = {
    namespaced: true,
    state: getDefaultStateAuthentication(),
    actions: {

        login({ commit }): void {
            commit('loginSuccess');
        },

        invalidTokenReceived({ commit }, data: Record<string, string | number>): void {
            commit('invalidTokenReceived', data);
        },
    },
    mutations: {
        resetState(state: AuthenticationStoreState): void {
            Object.assign(state, getDefaultStateAuthentication());
        },
        loginSuccess(state: AuthenticationStoreState): void {
            state.loggedIn = true;
        },
        invalidTokenReceived(state: AuthenticationStoreState, data: Record<string, any>): void {
            const { url, date } = data;
            state.invalidTokenRequestPass = false;

            if (!state.invalidTokenRequests[url]) {
                state.invalidTokenRequests[url] = [];
            }
            if (state.invalidTokenRequests[url].length > 2) {
                const firstEl = state.invalidTokenRequests[url][0];
                if ((date - firstEl) < 15000) {
                    delete state.invalidTokenRequests[url];
                    state.invalidTokenRequestPass = true;
                }
            } else {
                state.invalidTokenRequests[url].push(date);
            }
        },
    },
    getters: {
        isLoggedIn: (state: Record<string, any>): boolean => state.loggedIn,
        shouldBypassInvalidToken: (state: Record<string, any>): boolean => state.invalidTokenRequestPass,
    },
};

export default authenticationStore;
