
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Icon extends Vue {
    @Prop() icon!: string;
    @Prop() state!: string;
    @Prop() animation!: string;
    @Prop({ default: false }) private clickable!: boolean;
    @Prop({ default: false }) private extraLarge!: boolean;
    @Prop({ default: false }) private large!: boolean;
    @Prop({ default: false }) private small!: boolean;
    @Prop({ default: false }) private extraSmall!: boolean;
    @Prop({ default: false }) private flipV!: boolean;
    @Prop({ default: false }) private inverted!: boolean;

    get stateClass(): Record<string, boolean> {
        return {
            'icon--high-score': this.state === 'success',
            'icon--low-score': this.state === 'error',
            'icon--animate-rotate': this.animation === 'rotate',
            'icon--clickable': this.clickable,
            'icon--extralarge': this.extraLarge,
            'icon--large': this.large,
            'icon--small': this.small,
            'icon--extrasmall': this.extraSmall,
            'icon--flip-v': this.flipV,
            'icon--inverted': this.inverted,
        };
    }

    get viewBox(): string {
        switch (this.icon) {
            case 'chevron-down':
                return '0 -4 24 24';
            case 'chevron-right':
                return '-8 -3 24 24';
            case 'comments':
                return '0 0 56 56';
            case 'heatmap':
                return '0 0 56 56';
            case 'comment-anonym':
            case 'smiley-sad':
            case 'smiley-wink':
            case 'smiley-surprise':
                return '0 0 48 48';
            case 'indicator':
                return '0 0 12 25';
            case 'download':
                return '0 0 12 15';
            default:
                return '0 0 24 24';
        }
    }
}
