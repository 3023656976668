import { AutoReloadStoreState } from '@/interfaces/stores';

export const getDefaultStateReload = ():AutoReloadStoreState => ({
    autoReload: false,
    firstLoad: true,
});

const reloadStore = {
    namespaced: true,
    state: getDefaultStateReload(),
    mutations: {
        resetState(state: AutoReloadStoreState): void {
            Object.assign(state, getDefaultStateReload());
        },
        updateAutoReload(state: AutoReloadStoreState, autoReload: boolean): void {
            state.autoReload = autoReload;
        },
        updateFirstLoad(state: AutoReloadStoreState, firstLoad: boolean): void {
            state.firstLoad = firstLoad;
        },
    },
};

export default reloadStore;
