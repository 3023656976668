import { BffHeatmapData, BffHeatmapResponse } from '@/interfaces/bff';
import {
    resultTypeNormal,
    resultTypeMissing,
    resultTypeAnonym,
    heatmapDataTypeQuestion,
    heatmapResultTypeAbsolute,
} from '@/constants/';

export const bffHeatmapData: BffHeatmapData = {
    type: heatmapResultTypeAbsolute,
    columns: ['Self-Assessment', '#all#', 'Team', 'Peers', 'Others'],
    data: [{
        externalVariableNames: 'v_1,v_25,v_32,v_33',
        id: 5841,
        label: 'Agility',
        metaname: 'agility',
        min: 1,
        max: 5,
        values: [
            { value: 3.5, type: resultTypeNormal },
            { value: 2.8333333, type: resultTypeNormal },
            { value: 3.375, type: resultTypeNormal },
            { value: 1.75, type: resultTypeNormal },
            { value: -1, type: resultTypeMissing }
        ],
        relativeValues: [
            { value: 3.5, type: resultTypeNormal },
            { value: -0.6666667, type: resultTypeNormal },
            { value: -0.125, type: resultTypeNormal },
            { value: -1.75, type: resultTypeNormal },
            { value: -1, type: resultTypeMissing }
        ]
    }, {
        externalVariableNames: 'v_40',
        id: 5846,
        label: 'Collaboration',
        metaname: 'collaboration',
        min: 1,
        max: 5,
        values: [
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing }
        ],
        relativeValues: [
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing }
        ]
    }, {
        externalVariableNames: 'v_39',
        id: 5848,
        label: 'Communication',
        metaname: 'communication',
        min: 1,
        max: 5,
        values: [
            { value: -1, type: resultTypeMissing },
            { value: 3.3333333, type: resultTypeNormal },
            { value: 3.5, type: resultTypeNormal },
            { value: null, type: resultTypeAnonym },
            { value: -1, type: resultTypeMissing }
        ],
        relativeValues: [
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing }
        ]
    }, {
        externalVariableNames: 'v_38',
        id: 5850,
        label: 'Health',
        metaname: 'health',
        min: 1,
        max: 5,
        values: [
            { value: -1, type: resultTypeMissing },
            { value: 3, type: resultTypeNormal },
            { value: 3, type: resultTypeNormal },
            { value: null, type: resultTypeAnonym },
            { value: -1, type: resultTypeMissing }
        ],
        relativeValues: [
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing }
        ]
    }, {
        externalVariableNames: 'v_23,v_26,v_27,v_28,v_29,v_30,v_31',
        id: 5854,
        label: 'Performance',
        metaname: 'performance',
        min: 1,
        max: 4,
        values: [
            { value: 2.6, type: resultTypeNormal },
            { value: 2.6190476, type: resultTypeNormal },
            { value: 3, type: resultTypeNormal },
            { value: null, type: resultTypeAnonym },
            { value: -1, type: resultTypeMissing }
        ],
        relativeValues: [
            { value: 2.6, type: resultTypeNormal },
            { value: 0.019047599999999942, type: resultTypeNormal },
            { value: 0.3999999999999999, type: resultTypeNormal },
            { value: null, type: resultTypeAnonym },
            { value: -1, type: resultTypeMissing }
        ]
    }, {
        externalVariableNames: 'v_37',
        id: 5862,
        label: 'Security',
        metaname: 'security',
        min: 1,
        max: 5,
        values: [
            { value: -1, type: resultTypeMissing },
            { value: 2, type: resultTypeNormal },
            { value: 1.5, type: resultTypeNormal },
            { value: null, type: resultTypeAnonym },
            { value: -1, type: resultTypeMissing }
        ],
        relativeValues: [
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing }
        ]
    }],
    constructLabel: 'FETest (Dashbo2020MoreCats)',
    splitVariable: 'u_guidewave_rater_group',
    dataType: heatmapDataTypeQuestion,
    locale: 'en_US'
};

export const bffHeatmapResponse: BffHeatmapResponse = {
    data: bffHeatmapData,
    status: 200,
}


export const bffHeatmapDataForQuestions: BffHeatmapData = {
    type: heatmapResultTypeAbsolute,
    columns: [
        'Self-Assessment',
        '#all#',
        'Team',
        'Peers',
        'Other'
    ],
    data: [
        {
            label: 'First question',
            externalVariableName: 'v_1',
            min: 1,
            max: 5,
            values: [
                {
                    value: 2,
                    type: resultTypeNormal
                },
                {
                    value: 5,
                    type: resultTypeNormal
                },
                {
                    value: 5,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            relativeValues: [
                {
                    value: 2,
                    type: resultTypeNormal
                },
                {
                    value: +3,
                    type: resultTypeNormal
                },
                {
                    value: +3,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            sectionName: 'agility',
            sectionLabel: 'Agility'
        },
        {
            label: 'First question plus plus',
            externalVariableName: 'v_25',
            min: 1,
            max: 5,
            values: [
                {
                    value: 3,
                    type: resultTypeNormal
                },
                {
                    value: 4.5,
                    type: resultTypeNormal
                },
                {
                    value: 4.5,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            relativeValues: [
                {
                    value: 3,
                    type: resultTypeNormal
                },
                {
                    value: +1.5,
                    type: resultTypeNormal
                },
                {
                    value: +1.5,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            sectionName: 'agility',
            sectionLabel: 'Agility'
        },
        {
            label: 'Question about communication',
            externalVariableName: 'v_39',
            min: 1,
            max: 5,
            values: [
                {
                    value: 4,
                    type: resultTypeNormal
                },
                {
                    value: 3,
                    type: resultTypeNormal
                },
                {
                    value: 3,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            relativeValues: [
                {
                    value: 4,
                    type: resultTypeNormal
                },
                {
                    value: -1,
                    type: resultTypeNormal
                },
                {
                    value: -1,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            sectionName: 'communication',
            sectionLabel: 'Communication'
        },
        {
            label: 'Question about health',
            externalVariableName: 'v_38',
            min: 1,
            max: 5,
            values: [
                {
                    value: 4,
                    type: resultTypeNormal
                },
                {
                    value: 4,
                    type: resultTypeNormal
                },
                {
                    value: 4,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            relativeValues: [
                {
                    value: 4,
                    type: resultTypeNormal
                },
                {
                    value: 0,
                    type: resultTypeNormal
                },
                {
                    value: 0,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            sectionName: 'health',
            sectionLabel: 'Health'
        },
        {
            label: 'Second question',
            externalVariableName: 'v_23',
            min: 1,
            max: 4,
            values: [
                {
                    value: 3,
                    type: resultTypeNormal
                },
                {
                    value: 3.5,
                    type: resultTypeNormal
                },
                {
                    value: 3.5,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            relativeValues: [
                {
                    value: 2,
                    type: resultTypeNormal
                },
                {
                    value: +1.5,
                    type: resultTypeNormal
                },
                {
                    value: +1.5,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            sectionName: 'performance',
            sectionLabel: 'Performance'
        },
        {
            label: 'Second question pluplus',
            externalVariableName: 'v_26',
            min: 1,
            max: 4,
            values: [
                {
                    value: 2,
                    type: resultTypeNormal
                },
                {
                    value: 3.5,
                    type: resultTypeNormal
                },
                {
                    value: 3.5,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            relativeValues: [
                {
                    value: 2,
                    type: resultTypeNormal
                },
                {
                    value: +1.5,
                    type: resultTypeNormal
                },
                {
                    value: +1.5,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            sectionName: 'performance',
            sectionLabel: 'Performance'
        },
        {
            label: 'First',
            externalVariableName: 'v_27',
            min: 1,
            max: 5,
            values: [
                {
                    value: 2,
                    type: resultTypeNormal
                },
                {
                    value: 3.5,
                    type: resultTypeNormal
                },
                {
                    value: 3.5,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            relativeValues: [
                {
                    value: 2,
                    type: resultTypeNormal
                },
                {
                    value: +1.5,
                    type: resultTypeNormal
                },
                {
                    value: +1.5,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            sectionName: 'performance',
            sectionLabel: 'Performance'
        },
        {
            label: 'Second',
            externalVariableName: 'v_28',
            min: 1,
            max: 5,
            values: [
                {
                    value: 3,
                    type: resultTypeNormal
                },
                {
                    value: 4,
                    type: resultTypeNormal
                },
                {
                    value: 4,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            relativeValues: [
                {
                    value: 3,
                    type: resultTypeNormal
                },
                {
                    value: +1,
                    type: resultTypeNormal
                },
                {
                    value: +1,
                    type: resultTypeNormal
                },
                {
                    value: null,
                    type: resultTypeAnonym
                },
                {
                    value: -1,
                    type: resultTypeMissing
                }
            ],
            sectionName: 'performance',
            sectionLabel: 'Performance'
        },
    ],
    dataType: heatmapDataTypeQuestion,
    splitVariable: 'u_guidewave_rater_group',
    constructLabel: 'FETest (Dashbo2020MoreCats)',
    locale: 'en_US'
};

export const bffHeatmapResponseForQuestions: BffHeatmapResponse = {
    data: bffHeatmapDataForQuestions,
    status: 200,
}

export const cardHeatmapData: BffHeatmapData = {
    type: heatmapResultTypeAbsolute,
    columns: ['Self-Assessment', 'Team', 'Peers', 'Others'],
    data: [{
        externalVariableNames: 'v_1,v_25,v_32,v_33',
        id: 5841,
        label: 'Agility',
        metaname: 'agility',
        min: 1,
        max: 5,
        values: [
            { value: 3.5, type: resultTypeNormal },
            { value: 3.375, type: resultTypeNormal },
            { value: 1.75, type: resultTypeNormal },
            { value: -1, type: resultTypeMissing }
        ],
        relativeValues: [
            { value: 3.5, type: resultTypeNormal },
            { value: -0.125, type: resultTypeNormal },
            { value: -1.75, type: resultTypeNormal },
            { value: -1, type: resultTypeMissing }
        ]
    }, {
        externalVariableNames: 'v_40',
        id: 5846,
        label: 'Collaboration',
        metaname: 'collaboration',
        min: 1,
        max: 5,
        values: [
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing }
        ],
        relativeValues: [
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing }
        ]
    }, {
        externalVariableNames: 'v_39',
        id: 5848,
        label: 'Communication',
        metaname: 'communication',
        min: 1,
        max: 5,
        values: [
            { value: -1, type: resultTypeMissing },
            { value: 3.5, type: resultTypeNormal },
            { value: null, type: resultTypeAnonym },
            { value: -1, type: resultTypeMissing }
        ],
        relativeValues: [
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing }
        ]
    }, {
        externalVariableNames: 'v_38',
        id: 5850,
        label: 'Health',
        metaname: 'health',
        min: 1,
        max: 5,
        values: [
            { value: -1, type: resultTypeMissing },
            { value: 3, type: resultTypeNormal },
            { value: null, type: resultTypeAnonym },
            { value: -1, type: resultTypeMissing }
        ],
        relativeValues: [
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing }
        ]
    }, {
        externalVariableNames: 'v_23,v_26,v_27,v_28,v_29,v_30,v_31',
        id: 5854,
        label: 'Performance',
        metaname: 'performance',
        min: 1,
        max: 4,
        values: [
            { value: 2.6, type: resultTypeNormal },
            { value: 3, type: resultTypeNormal },
            { value: null, type: resultTypeAnonym },
            { value: -1, type: resultTypeMissing }
        ],
        relativeValues: [
            { value: 2.6, type: resultTypeNormal },
            { value: 0.3999999999999999, type: resultTypeNormal },
            { value: null, type: resultTypeAnonym },
            { value: -1, type: resultTypeMissing }
        ]
    }, {
        externalVariableNames: 'v_37',
        id: 5862,
        label: 'Security',
        metaname: 'security',
        min: 1,
        max: 5,
        values: [
            { value: -1, type: resultTypeMissing },
            { value: 1.5, type: resultTypeNormal },
            { value: null, type: resultTypeAnonym },
            { value: -1, type: resultTypeMissing }
        ],
        relativeValues: [
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing },
            { value: -1, type: resultTypeMissing }
        ]
    }],
    constructLabel: 'FETest (Dashbo2020MoreCats)',
    splitVariable: 'u_guidewave_rater_group',
    dataType: heatmapDataTypeQuestion,
    locale: 'en_US'
};