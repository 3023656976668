import { BffNPS, BffNPSData, BffNPSResponse } from '@/interfaces/bff';

export const bffNPS: BffNPS = {
  variableName: 'dummy',
  top: 75,
  mid: 12,
  low: 46,
}

export const bffNPSData: BffNPSData = {
  constructLabel: 'NPS',
  locale: 'en_US',
  nps: bffNPS,
}

export const bffNPSResponse: BffNPSResponse = {
  status: 200,
  data: bffNPSData,
}
