import {
    BffOpenQuestionsResponse,
    BffOpenQuestionsResponseData,
    BffOpenQuestionsResponseQuestion
} from '@/interfaces/bff';
import {
    resultTypeNormal,
    resultTypeAnonym,
} from '@/constants';
import { constructLabel } from '@/fixtures';

export const comments: string[] = [
    // eslint-disable-next-line max-len
    'THISISAVERYLONGTESTWORDTOTESTIFTHETEMPLATEBREAKSTERRIBLY. New economy high-level. Those options are already baked in with this model cross sabers define the underlying principles that drive decisions and strategy for your design language closer to the metal and this is meaningless. We need to have a Come to Jesus meeting with Phil about his attitude time vampire we’re all in this together, even if our businesses function differently or groom the backlog or baseline the procedure and samepage your department hit the ground running.',
    // eslint-disable-next-line max-len
    'Three-martini lunch beef up, so not enough bandwidth 4-blocker. Back-end of third quarter c-suite synergestic actionables. Reach out mobile friendly what\'s the status on the deliverables for eow?. I am dead inside table the discussion , or one-sheet. Circle back this vendor is incompetent nor put it on the parking lot thinking outside the box, so onward and upward, productize the deliverables and focus on the bottom line yet prioritize these line items. Level the playing field what\'s our go to market strategy? turn the ship and those options are already baked in with this model, but a loss a day will keep you focus or fast track.',
    // eslint-disable-next-line max-len
    'Regroup going forward drink the Kool-aid, nor define the underlying principles that drive decisions and strategy for your design language yet a tentative event rundown is attached for your reference, including other happenings on the day you are most welcome to join us beforehand for a light lunch we would also like to invite you to other activities on the day, including the interim and closing panel discussions on the intersection of businesses and social innovation, and on building a stronger social innovation eco-system respectively so win-win. Action item touch base when does this sunset?',
    // eslint-disable-next-line max-len
    'Paddle on both sides churning anomalies. We need to socialize the comms with the wider stakeholder community workflow ecosystem yet strategic fit. We want to see more charts punter yet out of scope going forward. Zeitgeist (let\'s not try to) boil the ocean (here/there/everywhere) so streamline, for a set of certitudes based on deductions founded on false premise high performance keywords, yet big data.',
    // eslint-disable-next-line max-len
    'It is all exactly as i said, but i don\'t like it radical candor or can we parallel path or corporate synergy we have put the apim bol, temporarily so that we can later put the monitors on. Where the metal hits the meat viral engagement thinking outside the box.',
    // eslint-disable-next-line max-len
    'Quantity five-year strategic plan. Put it on the parking lot product market fit but we need a paradigm shift, or close the loop and ladder up / ladder back to the strategy. No scraps hit the floor exposing new ways to evolve our design language for incentivization 4-blocker. The horse is out of the barn upstream selling design thinking. Hit the ground running ping me and sorry i didn\'t get your email what the. Closing these latest prospects is like putting socks on an octopus cloud strategy so shoot me an email a set of certitudes based on deductions founded on false premise let\'s put a pin in that nor vec strategic high-level 30,000 ft view. Commitment to the cause imagineer. Organic growth this is not the hill i want to die on, but sorry i didn\'t get your email and i\'ll book a meeting so we can solution this before the sprint is over. Idea shower time to open the kimono for we need a recap by eod, cob or whatever comes first canatics exploratory investigation data masking circle back, back-end of third quarter. Horsehead offer gain traction.',
];

export const unscoredQuestions: BffOpenQuestionsResponseQuestion[] = [
    {
        id: 592,
        text: 'What is mostly missing in the office?',
        data: {
            numberOfResponses: 4,
            comments,
            type: resultTypeNormal,
        },
    },
    {
        id: 593,
        text: 'What disappoints you the most when you are getting feedback about your work?',
        data: {
            numberOfResponses: 4,
            comments,
            type: resultTypeNormal,
        },
    },
    {
        id: 594,
        text: 'What is mostly missing in the office',
        data: {
            numberOfResponses: 4,
            comments,
            type: resultTypeNormal,
        },
    },
    {
        id: 595,
        text: 'What is mostly missing in the office',
        data: {
            numberOfResponses: 4,
            comments,
            type: resultTypeNormal,
        },
    },
    {
        id: 596,
        text: 'This question has no comments and will never have',
        data: {
            numberOfResponses: 0,
            comments: [],
            type: resultTypeNormal,
        },
    },
    {
        id: 596,
        text: 'This question has no comments (anonym)',
        data: {
            numberOfResponses: 2,
            comments: [],
            type: resultTypeAnonym,
        },
    },
];

export const unscoredQuestionsResponseData: BffOpenQuestionsResponseData = {
    constructLabel,
    locale: 'en_US',
    questions: unscoredQuestions,
    total: 6,
};

export const unscoredQuestionsResponse: BffOpenQuestionsResponse = {
    status: 200,
    data: unscoredQuestionsResponseData,
};
