import { ActionContext } from 'vuex';
import { InteractiveCommentsStoreState } from '@/interfaces/stores';
import { InteractiveComment } from '@/interfaces/visual';
import { BaseParamsWithLocale } from '@/interfaces/services';
import { getInteractiveCommentsDV } from '@/services/dashboardServiceDV';
import { getInteractiveComments } from '@/services/interactiveCommentService';
import { mapToInteractiveComment } from '@/services/ModelMapperService';

export const getDefaultStateInteractiveComments = (): InteractiveCommentsStoreState => ({
    interactiveComments: [],
});

const interactiveCommentsStore = {
    namespaced: true,
    state: getDefaultStateInteractiveComments(),
    mutations: {
        resetState(state: InteractiveCommentsStoreState): void {
            Object.assign(state, getDefaultStateInteractiveComments());
        },
        setInteractiveComments(state: InteractiveCommentsStoreState, interactiveComments: InteractiveComment[]): void {
            state.interactiveComments = interactiveComments;
        },
    },
    actions: {
        async loadInteractiveComments({
            commit,
            dispatch,
            rootState,
            rootGetters,
        }: ActionContext<InteractiveCommentsStoreState, any>): Promise<any> {
            const params: BaseParamsWithLocale = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                locale: rootState.instance.locale,
            };

            let request;

            if (rootGetters['instance/isDVDashboard']) {
                request = getInteractiveCommentsDV(
                    params.instanceId,
                    params.waveId,
                    rootState.instance.commentConstruct,
                    params.locale,
                );
            } else {
                request = getInteractiveComments(params);
            }

            return request
                .then((res) => {
                    const interactiveComments = res.data.comments.map((comment) => mapToInteractiveComment(comment));
                    commit('setInteractiveComments', interactiveComments);
                }).catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },
    },
};

export default interactiveCommentsStore;
