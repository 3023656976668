import { ActionContext } from 'vuex';
import { HeatmapStoreState } from '@/interfaces/stores'; import {
    BffHeatmapData,
    BffHeatmapRow,
    BffSplitResponseData,
    BffSurveyFilter,
} from '@/interfaces/bff';
import { QuestionScoreMetric } from '@/interfaces/visual';
import {
    CategoryHeatmapParams,
    MultiRaterHeatmapParams,
    QuestionHeatmapParams,
} from '@/interfaces/services';

import {
    getMultiRaterHeatmapDV,
    getQuestionHeatmapDV,
    getCategoryHeatmapDV,
} from '@/services/dashboardServiceDV';
import {
    getMultiRaterHeatmap,
    getCategoryHeatmap,
    getQuestionHeatmap,
} from '@/services/heatmapService';
import { mapSplitToHeatmap } from '@/services/ModelMapperService';
import {
    resultTypeAnonym,
    resultTypeMissing,
    resultTypeNormal,
    heatmapDataTypeCategory,
    measureFav,
    raterGroupAllLabel,
} from '@/constants';
import i18nLocalization from '@/i18n/i18nLocalization';

export const getDefaultStateHeatmap = (): HeatmapStoreState => ({
    heatmapData: null,
    heatmapDataCard: null,
    dataType: heatmapDataTypeCategory,
    heatmapSurveyFilter: null,
});

function enrichHeatmapDataWithRelative(heatmapData: BffHeatmapData): BffHeatmapRow[] {
    return heatmapData.data.map((rowData) => {
        const dataWithRelative = rowData;
        dataWithRelative.relativeValues = rowData.values.map((val, index) => {
            const selfValue = rowData.values[0].value;
            if (val.value === -1 || selfValue === -1 || selfValue === null) {
                return {
                    value: -1,
                    type: resultTypeMissing,
                };
            }
            // First row is self perception, so we need the absolute value
            if (index === 0) {
                return {
                    value: val.value,
                    type: resultTypeNormal,
                };
            }

            if (val.value === null) {
                return {
                    value: val.value,
                    type: resultTypeAnonym,
                };
            }

            return {
                value: val.value - selfValue,
                type: resultTypeNormal,
            };
        });
        return dataWithRelative;
    });
}

function enrichHeatmapDataWithPercent(heatmapData: BffHeatmapData): BffHeatmapRow[] {
    const reducer = (acc: number, current: BffHeatmapRow) => (current.values[0].value ? acc + current.values[0].value : acc);
    const totalResponses = heatmapData.data.reduce(reducer, 0);

    return heatmapData.data.map((rowData) => {
        const dataWithPercent = rowData as BffHeatmapRow;

        dataWithPercent.values = rowData.values.map((valueCol) => {
            const valueColWithPercent = valueCol;
            valueColWithPercent.percentValue = -1;
            if (valueCol.type !== resultTypeAnonym) {
                const val = (valueCol.value && valueCol.value > -1) ? valueCol.value : 0;
                valueColWithPercent.percentValue = (val * 100) / totalResponses;
            }
            return valueColWithPercent;
        });

        return dataWithPercent;
    });
}

function mapHeatmapDataWithPercent(heatmapData: BffHeatmapData): BffHeatmapRow[] {
    return heatmapData.data.map((rowData) => {
        const dataWithPercent = rowData as BffHeatmapRow;

        dataWithPercent.values = rowData.values.map((valueCol) => {
            const valueColWithPercent = valueCol;
            valueColWithPercent.percentValue = -1;
            const val = valueCol.value;
            if (valueCol.type !== resultTypeAnonym && val && val > -1) {
                valueColWithPercent.percentValue = val * 100;
            }
            return valueColWithPercent;
        });

        return dataWithPercent;
    });
}

const heatmapStore = {
    namespaced: true,
    state: getDefaultStateHeatmap(),
    mutations: {
        resetState(state: HeatmapStoreState): void {
            Object.assign(state, getDefaultStateHeatmap());
        },
        setHeatmapData(state: HeatmapStoreState, heatmapData: BffHeatmapData): void {
            state.heatmapData = heatmapData;
        },
        setHeatmapDataCard(state: HeatmapStoreState, heatmapDataCard: BffHeatmapData): void {
            const allIndex = heatmapDataCard.columns.findIndex((el) => el === '#all#');
            heatmapDataCard.columns.splice(allIndex, 1);

            heatmapDataCard.data.forEach((entry) => {
                entry.values?.splice(allIndex, 1);
                entry.relativeValues?.splice(allIndex, 1);
            });

            state.heatmapDataCard = heatmapDataCard;
        },
        setDataType(state: HeatmapStoreState, dataType: string): void {
            state.dataType = dataType;
        },
        setHeatmapSurveyFilter(state: HeatmapStoreState, surveyFilter: BffSurveyFilter): void {
            state.heatmapSurveyFilter = surveyFilter;
        },
    },
    actions: {
        async loadHeatmapDataForRaterGroups(
            {
                commit,
                dispatch,
                rootState,
                rootGetters,
            }: ActionContext<HeatmapStoreState, any>,
            heatmapParams: { hasSelfPerception: boolean },
        ): Promise<any> {
            const params: MultiRaterHeatmapParams = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                locale: rootState.instance.locale,
                dataType: rootState.heatmap.dataType,
            };

            let request;

            if (rootGetters['instance/isDVDashboard']) {
                request = getMultiRaterHeatmapDV(
                    params.instanceId,
                    params.waveId,
                    params.locale,
                    rootState.instance.feedbackConstruct,
                    params.dataType,
                );
            } else {
                request = getMultiRaterHeatmap(params);
            }

            return request
                .then((res) => {
                    const heatmapData: BffHeatmapData = res.data;

                    const allIndex = heatmapData.columns.findIndex((el) => el === '#all#');

                    /*  istanbul ignore else */
                    if (allIndex >= 0) {
                        heatmapData.columns[allIndex] = i18nLocalization.t(raterGroupAllLabel.toLowerCase()) as string;
                    }

                    /*  istanbul ignore else */
                    if (heatmapParams.hasSelfPerception && heatmapData.data) {
                        heatmapData.data = enrichHeatmapDataWithRelative(heatmapData);
                    }

                    commit('setHeatmapData', heatmapData);
                }).catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },

        async loadCardHeatmapDataForRaterGroups(
            {
                commit,
                dispatch,
                rootState,
                rootGetters,
            }: ActionContext<HeatmapStoreState, any>,
        ): Promise<any> {
            const params: MultiRaterHeatmapParams = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                locale: rootState.instance.locale,
                dataType: heatmapDataTypeCategory,
            };

            let request;

            if (rootGetters['instance/isDVDashboard']) {
                request = getMultiRaterHeatmapDV(
                    params.instanceId,
                    params.waveId,
                    params.locale,
                    rootState.instance.feedbackConstruct,
                    params.dataType,
                );
            } else {
                request = getMultiRaterHeatmap(params);
            }

            return request
                .then((res) => {
                    const heatmapDataCard: BffHeatmapData = res.data;
                    commit('setHeatmapDataCard', heatmapDataCard);
                }).catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },

        async loadHeatmapDataForQuestion(
            {
                commit,
                dispatch,
                rootState,
                rootGetters,
            }: ActionContext<HeatmapStoreState, any>,
            heatmapParams: {
                scoreMetric: QuestionScoreMetric,
                splitVariable: string,
            },
        ): Promise<any> {
            const { scoreMetric, splitVariable } = heatmapParams;

            const params: QuestionHeatmapParams = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                questionId: scoreMetric.id?.toString() || '',
                splitVariable,
                locale: rootState.instance.locale,
                selectedFilterScales: rootState.surveyFilters.selectedFilterScales,
            };

            let request;

            if (rootGetters['instance/isDVDashboard']) {
                request = getQuestionHeatmapDV(
                    params.instanceId,
                    params.waveId,
                    scoreMetric.externalVariableName,
                    splitVariable,
                    params.locale,
                    params.selectedFilterScales,
                );
            } else {
                request = getQuestionHeatmap(params);
            }

            return request
                .then((res) => {
                    const heatmapRawData: BffSplitResponseData = res.data;
                    const allIndex = heatmapRawData.columns.findIndex((el) => el === '#all#');

                    /*  istanbul ignore else */
                    if (allIndex >= 0) {
                        heatmapRawData.columns[allIndex] = i18nLocalization.t('splitAll') as string;
                    }

                    const heatmapData = mapSplitToHeatmap(
                        heatmapRawData,
                        scoreMetric.minScore,
                        scoreMetric.maxScore,
                        scoreMetric.externalVariableName,
                        splitVariable,
                        params.locale,
                    );
                    heatmapData.data = enrichHeatmapDataWithPercent(heatmapData);

                    commit('setHeatmapData', heatmapData);
                }).catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },

        async loadHeatmapDataForCategories(
            {
                commit,
                dispatch,
                rootState,
                rootGetters,
                state,
            }: ActionContext<HeatmapStoreState, any>,
        ): Promise<any> {
            const { dataType, heatmapSurveyFilter } = state;

            const params: CategoryHeatmapParams = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                locale: rootState.instance.locale,
                dataType,
                measure: rootState.instance.mainMeasure,
                surveyFilterVariable: heatmapSurveyFilter?.varname || '',
            };

            let request;

            if (rootGetters['instance/isDVDashboard']) {
                request = getCategoryHeatmapDV(
                    params.instanceId,
                    params.waveId,
                    params.locale,
                    rootState.instance.feedbackConstruct,
                    params.dataType,
                    params.measure,
                    params.surveyFilterVariable,
                );
            } else {
                request = getCategoryHeatmap(params);
            }

            return request
                .then((res) => {
                    const heatmapData: BffHeatmapData = res.data;

                    const allIndex = heatmapData.columns.findIndex((el) => el === '#all#');

                    /*  istanbul ignore else */
                    if (allIndex >= 0) {
                        heatmapData.columns[allIndex] = i18nLocalization.t(raterGroupAllLabel.toLowerCase()) as string;
                    }

                    if (params.measure === measureFav) {
                        heatmapData.data = mapHeatmapDataWithPercent(heatmapData);
                    }

                    commit('setHeatmapData', heatmapData);
                }).catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },
    },
};

export default heatmapStore;
