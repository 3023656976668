// eslint-disable-next-line max-classes-per-file
export const errorTypeInvalid = 'invalid';
export const errorTypeExpired = 'expired';

export const authenticationErrorType = 'AuthenticationError';
export const authorizationErrorType = 'AuthorizationError';
export const notFoundErrorType = 'NotFoundError';
export const anonymityErrorType = 'AnonymityError';
export const validationErrorType = 'ValidationError';
export const browserErrorType = 'BrowserError';
export const notAvailableErrorType = 'NotAvailableError';
export const cancelledErrorType = 'CancelledError';
export const internalErrorType = 'InternalError';

export class AuthenticationError extends Error {
    public type: string;
    public url: string;

    constructor(message: string, type: string, url: string) {
        super(message);
        this.name = authenticationErrorType;
        this.type = type;
        this.url = url;
    }
}

export class AuthorizationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = authorizationErrorType;
    }
}

export class NotFoundError extends Error {
    constructor(message: string) {
        super(message);
        this.name = notFoundErrorType;
    }
}

export class AnonymityError extends Error {
    constructor(message: string) {
        super(message);
        this.name = anonymityErrorType;
    }
}

export class ValidationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = validationErrorType;
    }
}

export class BrowserError extends Error {
    constructor(message: string) {
        super(message);
        this.name = browserErrorType;
    }
}

export class NotAvailableError extends Error {
    constructor(message: string) {
        super(message);
        this.name = notAvailableErrorType;
    }
}

export class CancelledError extends Error {
    constructor(message: string) {
        super(message);
        this.name = cancelledErrorType;
    }
}

export class InternalError extends Error {
    public details: any;

    constructor(message: string, details = {}) {
        super(message);
        this.name = internalErrorType;
        this.details = details;
    }
}
