import httpClient from '@/clients/httpClient';
import { validateEmpty } from '@/services/utilities';
import { BffSurveyFilterResponse } from '@/interfaces/bff';
import { BaseParamsWithLocale } from '@/interfaces/services';

/* URLs */
const getSurveyFiltersUrl = '/api/instance/{instanceId}/filters';

/*
   * Survey filters (list of filters configured for a dashboard, used for filters and splits)
   * Used on overview page for question template (filters and splits)
   * Used on categories comparison page for category template (comparison by survey filters)
 */
// eslint-disable-next-line import/prefer-default-export
export const getSurveyFilters = async ({
    instanceId,
    waveId,
    locale,
}: BaseParamsWithLocale): Promise<BffSurveyFilterResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params = { waveId, locale };
    let url = getSurveyFiltersUrl;
    url = url.replace('{instanceId}', instanceId);

    return httpClient.get(
        url,
        { params },
    )
        .then((response: BffSurveyFilterResponse) => response);
};
