import { BffResponseRates, BffResponseRatesResponse } from '@/interfaces/bff';


export const responseRatesData: BffResponseRates = {
    constructLabel: 'some text',
    openParticipation: false,
    totalAnswered: 99,
    totalParticipants: 140,
    locale: 'en_US',
};

export const responseRatesResponse: BffResponseRatesResponse = {
    status: 200,
    data: responseRatesData,
};

// ------------------------------------------------------------------------------------------------

export const responseRatesSimpleData: BffResponseRates = {
    constructLabel: 'some text',
    openParticipation: false,
    totalAnswered: 99,
    totalParticipants: 0,
    locale: 'en_US',
};

export const responseRatesSimpleResponse: BffResponseRatesResponse = {
    status: 200,
    data: responseRatesSimpleData,
};
