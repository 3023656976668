
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FramedSwitchInterface } from '@/interfaces/components';
import { OptionItem } from '@/interfaces/visual';

@Component({})
export default class FramedSwitch extends Vue implements FramedSwitchInterface {
    @Prop({ default: [] }) options!: OptionItem[];
    @Prop({ default: 0 }) value!: string;
    @Prop({ default: null }) linkTourKey!: string;

    emitInput(value: string): void {
        if (this.value !== value) {
            this.$emit('input', value);
        }
    }
}
