export const uniqueId = (): string => `_${Math.random().toString(36).substr(2, 9)}`;

export const getImageResizedUrl = (url: string, size: number): string => (
    url.replace('a.storyblok.com', `img2.storyblok.com/${size}x0`)
);

export const numberToLocaleString = (
    value: number,
    minFractions: number = 0,
    maxFractions: number = 17,
): string => (
    value.toLocaleString(undefined, {
        minimumFractionDigits: minFractions,
        maximumFractionDigits: maxFractions,
    }));

export const getColorContrast = (color: string): string => {
    if (!color) {
        return 'black';
    }
    // eslint-disable-next-line max-len
    const rgbExp = /^rgba?[\s+]?\(\s*(([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]))\s*,\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\s*,\s*([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\s*,?(?:\s*([\d.]+))?\s*\)?\s*/im;
    const hexExp = /^(?:#)|([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/igm;
    const rgb = color.match(rgbExp);
    const hex = color.match(hexExp);
    let hexString = '';
    let r;
    let g;
    let b;

    /* istanbul ignore else */
    if (rgb) {
        r = parseInt(rgb[1], 10);
        g = parseInt(rgb[2], 10);
        b = parseInt(rgb[3], 10);
    } else if (hex) {
        if (hex.length > 1) {
            // eslint-disable-next-line prefer-destructuring
            hexString = hex[1];
        } else {
            // eslint-disable-next-line prefer-destructuring
            hexString = hex[0];
        }
        if (hexString.length === 3) {
            hexString = `${hexString[0]}${hexString[0]}${hexString[1]}${hexString[1]}${hexString[2]}${hexString[1]}`;
        }
        r = parseInt(hexString.substr(0, 2), 16);
        g = parseInt(hexString.substr(2, 2), 16);
        b = parseInt(hexString.substr(4, 2), 16);
    }

    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq && yiq >= 150) ? 'black' : 'white';
};

export const youtubeVideoHTML = `<div class="article__iframe-embed">
                                <iframe width="100%" height="auto"
                                frameborder="0"
                                src="https://www.youtube.com/embed/#videoCode#"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen="">
                            </iframe>
                            </div>`;
export const vimeoVideoHTML = `<div class="article__iframe-embed">
                                <iframe width="100%" height="auto"
                                frameborder="0"
                                src="https://player.vimeo.com/video/#videoCode#"
                                allowfullscreen="">
                            </iframe>
                            </div>`;
export const contentIframeHTML = `<div class="article__iframe-embed">
                                <iframe width="100%" height="auto"
                                frameborder="0"
                                src="#URL#"
                                allowfullscreen="">
                            </iframe>
                            </div>`;
export const linkedPdfHTML = `<div class="article__pdf-embed">
                                <embed width="100%" height="100%"
                                src="#URL#"
                                type="application/pdf"
                                >
                            </embed>
                            </div>`;
export const linkedImageHTML = `<div class="article-list__card-teaser-preview">
                                    <img src="#URL#">
                                </div>`;
/* eslint-enable max-len */
