import { AxiosResponse } from 'axios';
import httpClient from '@/clients/httpClient';
import { validateEmpty, validateIsSet } from '@/services/utilities';
import { QuestionActionParams, QuestionActionPayload } from '@/interfaces/services';

/* URLs */
const postQuestionActionUrl = '/api/instance/{instanceId}/action-relations';

/*
   * Save question action
   * Used on question list page for multi rater template
 */
// eslint-disable-next-line import/prefer-default-export
export const saveQuestionAction = async ({
    instanceId,
    waveId,
    questionId,
    actionId,
}: QuestionActionParams): Promise<AxiosResponse<boolean>> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId as string);
    validateIsSet('Question-ID', questionId);
    validateIsSet('Action-ID', actionId);

    const payload: QuestionActionPayload = {
        waveId: parseInt(String(waveId), 10),
        questionId,
        actionId,
    };
    let url = postQuestionActionUrl;
    url = url.replace('{instanceId}', instanceId);

    return httpClient.post(url, payload)
        .then((response) => response);
};
