
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { inIframe } from '@/services/browserApiService';

@Component({})
export default class App extends Vue {
    clearSessionStorage(): void {
        try {
            const keys = Object.keys(localStorage);
            if (keys.length > 0) {
                keys.forEach((key) => {
                    if (key.indexOf('expandedComment_') > -1) {
                        localStorage.removeItem(key);
                    }
                });
            }
        } catch (e) {
            // Do nothing
        }
    }

    get insideIframe(): boolean {
        return inIframe();
    }

    created(): void {
        if (inIframe()) {
            document.body.style.backgroundColor = 'transparent';
        }

        this.clearSessionStorage();
    }
}
