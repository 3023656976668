import axios from 'axios';
import Vue from 'vue';
import { ActionContext } from 'vuex';
import cloneDeep from 'clone-deep';
import { IsSavingQuestionAction, ScoredQuestionsState } from '@/interfaces/stores';
import { QuestionScoreMetric } from '@/interfaces/visual';
import {
    BffQuestionsResponseQuestion,
    BffQuestionsResponseQuestionFavorable,
} from '@/interfaces/bff';
import { AggregatedQuestionListParams, QuestionActionParams, QuestionListParams } from '@/interfaces/services';
import {
    getAggregatedScoredQuestionsDV,
    getScoredQuestionsDV,
} from '@/services/dashboardServiceDV';
import {
    getScoredQuestions,
    getAggregatedScoredQuestions,
} from '@/services/questionService';
import { saveQuestionAction } from '@/services/actionService';
import { inIframe } from '@/services/browserApiService';
import {
    mapToQuestionFavorableMetric,
    mapToQuestionScoreMetric,
} from '@/services/ModelMapperService';
import {
    measureFav,
    measurementLevelNominal,
    actionStatusNone,
    actionStatusSeeAction,
    actionStatusTakeAction,
} from '@/constants';
import { cancelledErrorType } from '@/helpers/error';
import i18nLocalization from '@/i18n/i18nLocalization';

export const getDefaultStateScoredQuestions = (): ScoredQuestionsState => ({
    scoredQuestions: [],
    cancelQuestionRequest: null,
    creatingActionListener: false,
    isSavingQuestionAction: {
        value: false,
        questionId: null,
    },
});

export const showErrorNotification = (globalVue, questionId: number): void => {
    globalVue.notify({
        group: 'global',
        text: i18nLocalization.t('actionError') as string,
        type: 'error',
        duration: 8000,
    });
    const questionEl = document.querySelector(`[data-id="${questionId}"]`);
    if (questionEl) {
        Vue.nextTick(() => {
            const notificationEl = document.querySelector('.vue-notification-group');
            if (notificationEl) {
                questionEl.prepend(notificationEl);
            }
        });
    }
};

const scoredQuestionsStore = {
    namespaced: true,
    state: getDefaultStateScoredQuestions(),
    getters: {
        firstTrendIndex(state: ScoredQuestionsState): number {
            return state.scoredQuestions.findIndex((q) => q.trend !== 0 && !q.locked);
        },
        firstDefaultMeasurementQuestionIndex(state: ScoredQuestionsState): number {
            return state.scoredQuestions.findIndex((q: QuestionScoreMetric) => q.measurementLevel !== measurementLevelNominal && !q.locked);
        },
        firstNominalMeasurementQuestionIndex(state: ScoredQuestionsState): number {
            return state.scoredQuestions.findIndex((q: QuestionScoreMetric) => [measurementLevelNominal].includes(q.measurementLevel) && !q.locked);
        },
        firstDefaultScoredQuestionIndex(state: ScoredQuestionsState): number {
            return state.scoredQuestions.findIndex((q: QuestionScoreMetric) => (
                q.measurementLevel !== measurementLevelNominal
                && !q.locked
                && q.score > -1
            ));
        },
        firstNominalScoredQuestionIndex(state: ScoredQuestionsState): number {
            return state.scoredQuestions.findIndex((q: QuestionScoreMetric) => (
                [measurementLevelNominal].includes(q.measurementLevel)
                && !q.locked
                && q.score > -1
            ));
        },
        scoredQuestionsRequestInProgress(state: ScoredQuestionsState): boolean {
            return !!state.cancelQuestionRequest?.cancel;
        },
    },
    mutations: {
        resetState(state: ScoredQuestionsState): void {
            Object.assign(state, getDefaultStateScoredQuestions());
        },
        setScoredQuestions(state: ScoredQuestionsState, scoredQuestions: QuestionScoreMetric[]): void {
            state.scoredQuestions = scoredQuestions;
        },
        addScoredQuestionsRequest(state: ScoredQuestionsState): void {
            state.cancelQuestionRequest = axios.CancelToken.source();
        },
        resetScoredQuestionsRequest(state: ScoredQuestionsState): void {
            state.cancelQuestionRequest = null;
        },
        cancelScoredQuestionsRequest(state: ScoredQuestionsState): void {
            if (state.cancelQuestionRequest?.cancel) {
                state.cancelQuestionRequest.cancel();
            }
            state.cancelQuestionRequest = null;
        },
        setIsSavingQuestionAction(state: ScoredQuestionsState, isSavingAction: IsSavingQuestionAction) {
            const { value, questionId } = isSavingAction;
            state.isSavingQuestionAction = {
                value,
                questionId,
            };
        },
    },
    actions: {
        async loadScoredQuestions(
            {
                commit,
                dispatch,
                rootState,
                rootGetters,
                state,
            }: ActionContext<ScoredQuestionsState, any>,
            { category, area }: { category: string | null, area: string | undefined },
        ): Promise<any> {
            commit('addScoredQuestionsRequest');

            const params: QuestionListParams = {
                source: state.cancelQuestionRequest,
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                category,
                measure: rootState.instance.mainMeasure,
                locale: rootState.instance.locale,
                selectedFilterScales: rootState.surveyFilters.selectedFilterScales,
                area,
                raterGroup: rootState.multiRater.selectedRaterGroupMetaname,
            };

            let request;

            if (rootGetters['instance/isDVDashboard']) {
                request = getScoredQuestionsDV(
                    params.source,
                    params.instanceId,
                    params.waveId,
                    category ?? rootState.instance.feedbackConstruct,
                    params.measure,
                    params.locale,
                    params.selectedFilterScales,
                    params.area,
                    params.raterGroup,
                );
            } else {
                request = getScoredQuestions(params);
            }

            return request.then((res) => {
                const dashboardData = res.data;

                const bffQuestions = dashboardData.questions as any[];
                let scoredQuestions: QuestionScoreMetric[];

                if (params.measure === measureFav) {
                    scoredQuestions = bffQuestions.map((question: BffQuestionsResponseQuestionFavorable) => mapToQuestionFavorableMetric(question));
                } else {
                    scoredQuestions = bffQuestions.map((question: BffQuestionsResponseQuestion) => mapToQuestionScoreMetric(question));
                }

                commit('setScoredQuestions', scoredQuestions);
                commit('resetScoredQuestionsRequest');
            }).catch((error: Error) => {
                if (error.name !== cancelledErrorType) {
                    dispatch('errorStore/errorReceived', error, { root: true });
                }
            });
        },

        async loadAggregatedScoredQuestions(
            {
                commit,
                dispatch,
                rootState,
                rootGetters,
                state,
            }: ActionContext<ScoredQuestionsState, any>,
            { category }: { category: string | null },
        ): Promise<any> {
            commit('addScoredQuestionsRequest');

            const params: AggregatedQuestionListParams = {
                source: state.cancelQuestionRequest,
                instanceId: rootState.instance.instanceId,
                guideId: rootState.instance.guideId,
                category: category ?? rootState.instance.feedbackConstruct,
                locale: rootState.instance.locale,
            };

            let request;

            if (rootGetters['instance/isDVDashboard']) {
                request = getAggregatedScoredQuestionsDV(
                    params.source,
                    params.instanceId,
                    params.guideId,
                    params.category,
                    params.locale,
                );
            } else {
                request = getAggregatedScoredQuestions(params);
            }

            return request
                .then((res) => {
                    const dashboardData = res.data;

                    const bffQuestions = dashboardData.questions as any[];
                    const scoredQuestions: QuestionScoreMetric[] = bffQuestions
                        .map((question: BffQuestionsResponseQuestion) => mapToQuestionScoreMetric(question));

                    commit('setScoredQuestions', scoredQuestions);
                    commit('resetScoredQuestionsRequest');
                }).catch((error: Error) => {
                    if (error.name !== cancelledErrorType) {
                        dispatch('errorStore/errorReceived', error, { root: true });
                    }
                });
        },

        receivedActionMessage(
            { dispatch },
            event: Record<string, any>,
        ): void {
            const { data } = event;
            if (data?.action === 'externalImprovementsUpdate') {
                const { payload } = data;
                const { action } = payload;

                // eslint-disable-next-line default-case
                switch (action) {
                    case 'actionSaved': {
                        const { additionalData, actionData } = payload;
                        if (additionalData.persistent) {
                            const saveActionPayload = {
                                questionId: additionalData.questionId,
                                actionId: actionData.id,
                            };
                            dispatch('saveQuestionAction', saveActionPayload);
                        }
                        break;
                    }
                    case 'actionBoardMissing': {
                        const { sendActionData } = payload;
                        showErrorNotification(Vue, sendActionData.additionalData.questionId);
                        break;
                    }
                }
            }
        },

        async saveQuestionAction(
            {
                commit,
                rootState,
                state,
            }: ActionContext<ScoredQuestionsState, any>,
            { questionId, actionId }: { questionId: number, actionId: number },
        ): Promise<any> {
            commit('setIsSavingQuestionAction', {
                value: true,
                questionId,
            });

            const params: QuestionActionParams = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                questionId,
                actionId,
            };

            return saveQuestionAction(params).then(() => {
                const { scoredQuestions } = state;
                const scoredQuestionsCopy = cloneDeep(scoredQuestions);
                const updatedQuestion = scoredQuestionsCopy.filter((question) => question.id === questionId)[0];
                if (updatedQuestion) {
                    updatedQuestion.action = {
                        actionStatus: actionStatusSeeAction,
                        actionProperties: {
                            actionId,
                        },
                    };
                    commit('setScoredQuestions', scoredQuestionsCopy);
                }
            }).catch(() => {
                showErrorNotification(Vue, questionId);
            }).finally(() => {
                commit('setIsSavingQuestionAction', {
                    value: false,
                    questionId: null,
                });
            });
        },

        addQuestionAction(
            {
                state,
                dispatch,
                rootGetters,
            }: ActionContext<ScoredQuestionsState, any>,
            scoreMetric: QuestionScoreMetric,
        ): void {
            const { action, id } = scoreMetric;
            if (!action) {
                return;
            }
            const { actionProperties, actionStatus } = action;
            if (!actionProperties || actionStatus !== actionStatusTakeAction) {
                return;
            }
            const { recommendation } = actionProperties;

            /* istanbul ignore else */
            if (recommendation) {
                const { content } = recommendation;
                const category = rootGetters['categories/selectedCategory'];

                const receivedMessage = (event: Record<string, any>) => {
                    dispatch('receivedActionMessage', event);
                };

                const eventSource = inIframe() ? window.top : window.self;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                eventSource.postMessage({
                    action: 'createImprovement',
                    payload: {
                        title: scoreMetric.question,
                        description: content,
                        categories: category.title,
                        sendActionData: {
                            additionalData: {
                                questionId: id,
                                persistent: true,
                            },
                        },
                    },
                }, '*');

                if (!state.creatingActionListener) {
                    window.addEventListener('message', receivedMessage, false);
                    state.creatingActionListener = true;
                }
            }
        },

        seeQuestionAction(
            _,
            scoreMetric: QuestionScoreMetric,
        ): void {
            const { action } = scoreMetric;
            if (!action) {
                return;
            }
            const { actionProperties, actionStatus } = action;
            if (!actionProperties || actionStatus === actionStatusNone) {
                return;
            }
            const { actionId } = actionProperties;

            if (actionId) {
                const eventSource = inIframe() ? window.top : window.self;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                eventSource.postMessage({
                    action: 'seeImprovement',
                }, '*');
            }
        },

        addQuestionActionNotPersistent({
            state,
            dispatch,
            rootGetters,
        }: ActionContext<ScoredQuestionsState, any>, scoreMetric: QuestionScoreMetric): void {
            const eventSource = inIframe() ? window.top : window.self;
            const receivedMessage = (event: Record<string, any>) => {
                dispatch('receivedActionMessage', event);
            };
            const category = rootGetters['categories/selectedCategory'];

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            eventSource.postMessage({
                action: 'createImprovement',
                payload: {
                    categories: category.title,
                    sendActionData: {
                        additionalData: {
                            questionId: scoreMetric.id,
                            showBoardLink: true,
                            persistent: false,
                        },
                    },
                },
            }, '*');

            if (!state.creatingActionListener) {
                window.addEventListener('message', receivedMessage, false);
                state.creatingActionListener = true;
            }
        },
    },
};

export default scoredQuestionsStore;
