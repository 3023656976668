import { AxiosResponse, CancelTokenSource } from 'axios';
import {
    BffCategoryResponse,
    BffOpenQuestionsResponse,
    BffQuestionsFavorableResponse,
    BffQuestionsResponse,
    BffResponseRatesResponse,
    BffKpiResponse,
    BffKpiFavorableResponse,
    BffCategoryFavorableResponse,
    BffRaterGroupsResponse,
    BffHeatmapResponse,
    BffNPSResponse,
    BffInteractiveCommentsResponse,
    BffCategoryRaterGroupsResponse,
    BffSplitResponse,
    BffSurveyFilterResponseDV,
} from '@/interfaces/bff';
import { CmsArticleResponse, CmsArticlesResponse } from '@/interfaces/cms';

import httpClient from '@/clients/httpClient';
import {
    measureFav,
    measureMean,
    heatmapDataTypeCategory,
    allArea,
    raterGroupAll,
} from '@/constants';
import {
    dummyInstanceIdWithSplit,
    dummyInstanceIdMultiCategory,
    dummyInstanceIdMultiCategoryFavorable,
    dummyInstanceIdRaterGroups,
    dummyInstanceIds,
    dummyInstanceIdVibes,
} from '@/fixtures';
import {
    scoredQuestionsFavorableResponse,
    scoredQuestionsResponse,
    scoredQuestionsWithSelfPerceptionResponse,
} from '@/fixtures/bff/questionScores';
import { unscoredQuestionsResponse } from '@/fixtures/bff/openQuestions';
import { responseRatesResponse } from '@/fixtures/bff/responseRates';
import {
    categoryScoresFavorableResponse,
    categoryScoresResponse,
    categoryScoresWithSelfPerceptionOneLessResponse,
    categoryScoresWithSelfPerceptionResponse,
    categoryScoresRaterGroupsResponse,
} from '@/fixtures/bff/categoryScores';
import {
    positiveTeamPerforanceScoreFavorableResponse,
    positiveTeamPerforanceScoreResponse,
} from '@/fixtures/bff/teamPerformances';
import {
    articlesResponse, getDummyArticleResponse,
} from '@/fixtures/cms/articles';
import { raterGroupsFullResponse } from '@/fixtures/bff/raterGroups';
import { bffHeatmapResponse, bffHeatmapResponseForQuestions } from '@/fixtures/bff/heatmaps';
import { bffNPSResponse } from '@/fixtures/bff/nps';
import { bffInteractiveCommentsResponse } from '@/fixtures/bff/interactiveComments';
import { splitResponse } from '@/fixtures/bff/splits';
import { surveyFiltersResponse } from '@/fixtures/bff/surveyFilters';
import { surveyFiltersHeatmapResponse } from '@/fixtures/bff/surveyFiltersHeatmap';
import { selectedFilterScale } from '@/interfaces/stores';
import { CategoryScoreMetric } from '@/interfaces/visual';

import {
    validateEmpty,
    addFiltersToParams,
    validateIsSet,
} from '@/services/utilities';

export const GET_QUESTIONS_DATA = '/api/instance/{instanceId}/categories/{category}/question-list';
export const GET_AGGREGATED_QUESTIONS_DATA = '/api/instance/{instanceId}/categories/{category}/aggregated/area/question-list';
export const GET_QUESTIONS_DATA_AREA = '/api/instance/{instanceId}/categories/{category}/area/question-list';
export const GET_COMMENTS_DATA = '/api/instance/{instanceId}/categories/{category}/comments';
export const GET_RESPONSERATE_DATA = '/api/instance/{instanceId}/categories/{category}/response-rate';
export const GET_CATEGORIES_DATA = '/api/instance/{instanceId}/categories/{category}';
export const GET_CATEGORIES_DATA_AREA = '/api/instance/{instanceId}/categories/{category}/area';
export const GET_CATEGORIES_DATA_AGGREGATED = '/api/instance/{instanceId}/categories/{category}/aggregated/area';
export const GET_CATEGORY_DATA_RATER_GROUPS = '/api/instance/{instanceId}/categories/{category}/area/group-comparison';
export const GET_KPI = '/api/instance/{instanceId}/categories/{category}/kpi';
export const GET_ARTICLES = '/api/instance/{instanceId}/categories/{category}/articles';
export const GET_ARTICLE = '/api/instance/{instanceId}/articles/{locale}/{slug}';
export const GET_RATER_GROUPS = '/api/instance/{instanceId}/ratergroups';
export const GET_HEATMAP = '/api/instance/{instanceId}/categories/{categoryMetaname}/heatmap/rater-groups';
export const GET_HEATMAP_BY_SURVEY_FILTERS = '/api/instance/{instanceId}/categories/{categoryMetaname}/heatmap/survey-filters';
export const GET_NPS = '/api/instance/{instanceId}/categories/{categoryMetaname}/nps';
export const GET_INTERACTIVE_COMMENTS = '/api/instance/{instanceId}/categories/{categoryMetaname}/interactive-comments';
export const GET_SPLIT = '/api/instance/{instanceId}/question/{externalVarname}/split';
export const GET_SURVEY_FILTERS = '/api/instance/{instanceId}/filters/dv';
export const POST_QUESTION_ACTION = '/api/instance/{instanceId}/action-relations';

export const getScoredQuestionsDV = async (
    source: CancelTokenSource | null,
    instanceId: string,
    waveId: string,
    category: string | null,
    // eslint-disable-next-line default-param-last
    measure: string = measureMean, // =mean|percentFavorable
    locale: string,
    selectedFilterScales: selectedFilterScale[],
    area?: string,
    raterGroup?: string,
): Promise<BffQuestionsResponse | BffQuestionsFavorableResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        if (instanceId === dummyInstanceIdRaterGroups) {
            return Promise.resolve(scoredQuestionsWithSelfPerceptionResponse);
        }
        if (measure === measureFav) {
            return Promise.resolve(scoredQuestionsFavorableResponse);
        }
        return Promise.resolve(scoredQuestionsResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    let params = {
        waveId,
        locale,
        measure,
        area: (area && area !== allArea) ? area : undefined,
        raterGroup: (raterGroup && raterGroup !== raterGroupAll) ? raterGroup : undefined,
    };
    if (selectedFilterScales.length) {
        const filterParams = addFiltersToParams(selectedFilterScales);
        params = { ...params, ...filterParams };
    }

    let url = area ? GET_QUESTIONS_DATA_AREA : GET_QUESTIONS_DATA;
    url = url.replace('{instanceId}', instanceId);
    if (category) {
        url = url.replace('{instanceId}', instanceId).replace('{category}', category);
    }

    return httpClient.get(
        url,
        {
            cancelToken: source?.token,
            params,
        },
    )
        .then((response: BffQuestionsResponse | BffQuestionsFavorableResponse) => response);
};

export const getAggregatedScoredQuestionsDV = async (
    source: CancelTokenSource | null,
    instanceId: string,
    guideId: string,
    category: string,
    locale: string,
): Promise<BffQuestionsResponse | BffQuestionsFavorableResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Guide-ID', guideId);
    validateEmpty('Category', category);

    const params = {
        guideId,
        locale,
    };

    let url = GET_AGGREGATED_QUESTIONS_DATA;
    url = url.replace('{instanceId}', instanceId).replace('{category}', category);
    return httpClient.get(
        url,
        {
            cancelToken: source?.token,
            params,
        },
    )
        .then((response: BffQuestionsResponse | BffQuestionsFavorableResponse) => response);
};

export const getOpenQuestionsDV = async (
    source: CancelTokenSource | null,
    instanceId: string,
    waveId: string,
    category: string | null,
    locale: string,
    selectedFilterScales: selectedFilterScale[],
    raterGroup?: string,
): Promise<BffOpenQuestionsResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        return Promise.resolve(unscoredQuestionsResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    let params = {
        waveId,
        locale,
        raterGroup: (raterGroup && raterGroup !== raterGroupAll) ? raterGroup : undefined,
    };

    if (selectedFilterScales.length) {
        const filterParams = addFiltersToParams(selectedFilterScales);
        params = { ...params, ...filterParams };
    }

    let url = GET_COMMENTS_DATA.replace('{instanceId}', instanceId);
    if (category) {
        url = url.replace('{category}', category);
    }

    return httpClient.get(
        url,
        {
            cancelToken: source?.token,
            params,
        },
    )
        .then((response: BffOpenQuestionsResponse) => response);
};

export const getResponseRateDV = async (
    instanceId: string,
    waveId: string,
    category: string,
): Promise<BffResponseRatesResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        return Promise.resolve(responseRatesResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params = { params: { waveId } };
    let url = GET_RESPONSERATE_DATA;
    url = url.replace('{instanceId}', instanceId);
    if (category) {
        url = url.replace('{instanceId}', instanceId).replace('{category}', category);
    }

    return httpClient.get(url, params)
        .then((response: BffResponseRatesResponse) => response);
};

export const getCategoriesDV = async (
    instanceId: string,
    waveId: string,
    locale: string,
    construct: string | undefined,
    // eslint-disable-next-line default-param-last
    measure: string = measureMean,
    area?: string,
    raterGroup?: string,
): Promise<BffCategoryResponse | BffCategoryFavorableResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        if (instanceId === dummyInstanceIdRaterGroups) {
            if (raterGroup === 'sys_rg_peers') {
                return Promise.resolve(categoryScoresWithSelfPerceptionOneLessResponse);
            }
            return Promise.resolve(categoryScoresWithSelfPerceptionResponse);
        }
        if (measure === measureFav) {
            return Promise.resolve(categoryScoresFavorableResponse);
        }
        return Promise.resolve(categoryScoresResponse);
    }

    validateEmpty('Wave-ID', waveId);
    validateEmpty('Instance-ID', instanceId);

    const params = {
        params: {
            waveId,
            locale,
            measure,
            area: (area && area !== allArea) ? area : undefined,
            raterGroup: (raterGroup && raterGroup !== raterGroupAll) ? raterGroup : undefined,
        },
    };

    let url = area ? GET_CATEGORIES_DATA_AREA : GET_CATEGORIES_DATA;
    url = url.replace('{instanceId}', instanceId);
    if (construct) {
        url = url.replace('{category}', construct);
    }

    return httpClient.get(url, params)
        .then((response: BffCategoryResponse | BffCategoryFavorableResponse) => response);
};

export const getCategoriesAggregatedDV = async (
    instanceId: string,
    guideId: string,
    locale: string,
    construct: string | undefined,
    area?: string,
): Promise<BffCategoryResponse | BffCategoryFavorableResponse> => {
    validateEmpty('Guide-ID', guideId);
    validateEmpty('Instance-ID', instanceId);

    const params = {
        params: {
            guideId,
            locale,
            area: (area && area !== allArea) ? area : undefined,
        },
    };

    let url = GET_CATEGORIES_DATA_AGGREGATED;
    url = url.replace('{instanceId}', instanceId);
    if (construct) {
        url = url.replace('{category}', construct);
    }

    return httpClient.get(url, params)
        .then((response: BffCategoryResponse | BffCategoryFavorableResponse) => response);
};

export const getCategoryScoreForRaterGroupsDV = async (
    instanceId: string,
    waveId: string,
    construct: string,
    categoryMetanameFilter: string,
): Promise<BffCategoryRaterGroupsResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        return Promise.resolve(categoryScoresRaterGroupsResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Construct', construct);

    const params = {
        params: {
            waveId,
            categoryMetanameFilter,
        },
    };
    let url = GET_CATEGORY_DATA_RATER_GROUPS;
    url = url.replace('{instanceId}', instanceId);
    url = url.replace('{category}', construct);
    return httpClient.get(url, params)
        .then((response) => response);
};

export const getKpiDV = async (
    instanceId: string,
    waveId: string,
    locale: string,
    construct: string | undefined,
    measure: string = measureMean,
): Promise<BffKpiResponse | BffKpiFavorableResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        if (measure === measureFav) {
            return Promise.resolve(positiveTeamPerforanceScoreFavorableResponse);
        }
        return Promise.resolve(positiveTeamPerforanceScoreResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params = { params: { waveId, locale, measure } };
    let url = GET_KPI;
    url = url.replace('{instanceId}', instanceId);
    if (construct) {
        url = url.replace('{category}', construct);
    }

    return httpClient.get(url, params)
        .then((response: BffKpiResponse | BffKpiFavorableResponse) => response);
};

/* To be deleted */
export const getArticlesDV = async (
    instanceId: string,
    waveId: string,
    locale: string,
    cat: CategoryScoreMetric,
): Promise<CmsArticlesResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        return Promise.resolve(articlesResponse);
    }
    const construct = cat.key;

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Construct', construct);

    const params = { params: { waveId, locale } };
    let url = GET_ARTICLES;
    url = url.replace('{instanceId}', instanceId);
    url = url.replace('{category}', construct);
    return httpClient.get(url, params)
        .then((bffResponse: CmsArticlesResponse) => {
            const enrichedArticles = bffResponse.data.articles.map((article) => ({
                ...article,
                categoryLabel: cat.title,
            }));
            return {
                ...bffResponse,
                data: {
                    articles: enrichedArticles,
                },
            };
        });
};

/* To be deleted */
export const getArticleDV = async (
    instanceId: string,
    fullSlug: string,
    waveId: string,
): Promise<CmsArticleResponse> => {
    validateEmpty('fullSlug', fullSlug);

    const dummyArticleResponse = getDummyArticleResponse(fullSlug);
    if (dummyArticleResponse.status === 200) {
        return dummyArticleResponse;
    }

    validateEmpty('Wave-ID', waveId);
    validateEmpty('Instance-ID', instanceId);

    let slug = fullSlug;
    const slugParts = fullSlug.split('/');
    let urlLang = '';
    if (slugParts.length === 3) {
        urlLang = slugParts[0].replace('-', '_');
        slug = fullSlug.substring(fullSlug.indexOf(slugParts[2]));
    }

    const params = { params: { waveId } };

    let url = GET_ARTICLE;
    url = url.replace('{instanceId}', instanceId);
    url = url.replace('{slug}', slug);
    url = url.replace('{locale}', urlLang);
    return httpClient.get(url, params)
        .then((response: CmsArticleResponse) => response);
};

/* To be deleted */
export const getRaterGroupsDV = async (
    instanceId: string,
    waveId: string,
    locale: string,
): Promise<BffRaterGroupsResponse> => {
    if (instanceId === dummyInstanceIdRaterGroups) {
        return Promise.resolve(raterGroupsFullResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params = { params: { waveId, locale } };
    let url = GET_RATER_GROUPS;
    url = url.replace('{instanceId}', instanceId);
    return httpClient.get(url, params)
        .then((response: BffRaterGroupsResponse) => response);
};

export const getMultiRaterHeatmapDV = async (
    instanceId: string,
    waveId: string,
    locale: string,
    categoryMetaname: string,
    dataType: string,
): Promise<BffHeatmapResponse> => {
    if (dummyInstanceIdRaterGroups === instanceId) {
        if (dataType === heatmapDataTypeCategory) {
            return Promise.resolve(bffHeatmapResponse);
        }
        return Promise.resolve(bffHeatmapResponseForQuestions);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Category metaname', categoryMetaname);

    const params = { params: { waveId, locale, dataType } };
    let url = GET_HEATMAP;
    url = url.replace('{instanceId}', instanceId).replace('{categoryMetaname}', categoryMetaname);
    return httpClient.get(url, params)
        .then((response: BffHeatmapResponse) => response);
};

export const getCategoryHeatmapDV = async (
    instanceId: string,
    waveId: string,
    locale: string,
    categoryMetaname: string,
    // eslint-disable-next-line default-param-last
    dataType: string = heatmapDataTypeCategory,
    measure: string,
    surveyFilterVariable?: string,
): Promise<BffHeatmapResponse> => {
    const dummyIds = [
        dummyInstanceIdMultiCategory,
        dummyInstanceIdMultiCategoryFavorable,
    ];
    if (dummyIds.includes(instanceId)) {
        return Promise.resolve(surveyFiltersHeatmapResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Category metaname', categoryMetaname);

    const params = {
        params: {
            waveId,
            locale,
            surveyFilterVariable,
            dataType,
            measure,
        },
    };
    let url = GET_HEATMAP_BY_SURVEY_FILTERS;
    url = url.replace('{instanceId}', instanceId).replace('{categoryMetaname}', categoryMetaname);
    return httpClient.get(url, params)
        .then((response: BffHeatmapResponse) => response);
};

export const getNPSDV = async (
    instanceId: string,
    waveId: string,
    categoryMetaname: string,
    locale: string,
): Promise<BffNPSResponse> => {
    if (dummyInstanceIdVibes === instanceId) {
        return Promise.resolve(bffNPSResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Category metaname', categoryMetaname);

    const params = { params: { waveId, locale } };
    let url = GET_NPS;
    url = url.replace('{instanceId}', instanceId).replace('{categoryMetaname}', categoryMetaname);
    return httpClient.get(url, params)
        .then((response: BffNPSResponse) => response);
};

export const getInteractiveCommentsDV = async (
    instanceId: string,
    waveId: string,
    categoryMetaname: string,
    locale: string,
): Promise<BffInteractiveCommentsResponse> => {
    if (dummyInstanceIdVibes === instanceId) {
        return Promise.resolve(bffInteractiveCommentsResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Category metaname', categoryMetaname);

    const params = { params: { waveId, locale } };
    let url = GET_INTERACTIVE_COMMENTS;
    url = url.replace('{instanceId}', instanceId).replace('{categoryMetaname}', categoryMetaname);
    return httpClient.get(url, params)
        .then((response: BffInteractiveCommentsResponse) => response);
};

export const getQuestionHeatmapDV = async (
    instanceId: string,
    waveId: string,
    externalVarname: string,
    splitVariable: string,
    locale: string,
    selectedFilterScales?: selectedFilterScale[],
): Promise<BffSplitResponse> => {
    if (dummyInstanceIdWithSplit === instanceId) {
        return Promise.resolve(splitResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('External varname', externalVarname);
    validateEmpty('Split varname', splitVariable);

    let params = {
        waveId,
        splitVariable,
        locale,
    };

    if (selectedFilterScales && selectedFilterScales.length) {
        const filterParams = addFiltersToParams(selectedFilterScales);
        params = { ...params, ...filterParams };
    }

    let url = GET_SPLIT;
    url = url.replace('{instanceId}', instanceId).replace('{externalVarname}', externalVarname);
    return httpClient.get(
        url,
        { params },
    )
        .then((response: BffSplitResponse) => response);
};

export const getSurveyFiltersDV = async (
    instanceId: string,
    waveId: string,
    locale: string,
): Promise<BffSurveyFilterResponseDV> => {
    if (dummyInstanceIds.includes(instanceId)) {
        return Promise.resolve(surveyFiltersResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params = { params: { waveId, locale } };
    let url = GET_SURVEY_FILTERS;
    url = url.replace('{instanceId}', instanceId);
    return httpClient.get(url, params)
        .then((response: BffSurveyFilterResponseDV) => response);
};

/* To be deleted */
export const saveQuestionAction = async (
    instanceId: string,
    waveId: string,
    questionId: number | undefined,
    actionId: number | undefined,
): Promise<AxiosResponse<boolean>> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateIsSet('Question-ID', questionId);
    validateIsSet('Action-ID', actionId);

    const payload = {
        waveId: parseInt(waveId, 10),
        questionId,
        actionId,
    };
    let url = POST_QUESTION_ACTION;
    url = url.replace('{instanceId}', instanceId);
    return httpClient.post(url, payload)
        .then((response) => response);
};
