import { ExternalVuetifyTheme } from '@/interfaces/externalVuetifyTheme';

// eslint-disable-next-line import/prefer-default-export
export const basic: ExternalVuetifyTheme = {
    primary: {
        lighten1: '#EDE7F6',
        base: '#673AB7',
        darken1: '#58329B',
    },

    secondary: {
        lighten1: '#E0F3F4',
        base: '#00ACAD',
        darken1: '#00807C',
    },

    decorative1: '#B73A89',
    decorative2: '#3A4BB7',

    textPrimary: '#212B36',
    textSecondary: '#5E738A',

    criticalPrimary: '#D83543',
    criticalSecondary: '#FACAD1',

    warningPrimary: '#E3A12B',
    warningSecondary: '#FFF2DA',

    informativePrimary: '#673AB7',
    informativeSecondary: '#D6C8F3',

    successPrimary: '#208234',
    successSecondary: '#EBFFED',

    neutralShade: {
        lighten5: '#F4F6F8',
        lighten4: '#CCD5DE',
        lighten3: '#ADB9C6',
        lighten2: '#8D9DAE',
        lighten1: '#76879B',
        base: '#5E738A',
        darken1: '#516579',
        darken2: '#425263',
        darken3: '#333F4D',
        darken4: '#212B36',
    },

    primaryShade: {
        lighten5: '#EDE7F6', // 50
        lighten4: '#D1C4E9', // 100
        lighten3: '#B39DDB', // 200
        lighten2: '#9675CD', // 300
        lighten1: '#7F57C2', // 400
        base: '#673AB7', // 500
        darken1: '#5F35B1', // 600
        darken2: '#522DA8', // 700
        darken3: '#4627A0', // 800
        darken4: '#321B92', // 900
    },
};
