import { ActionContext } from 'vuex';
import { selectedFilterScale, SurveyFilterStoreState } from '@/interfaces/stores';
import { BffSurveyFilter } from '@/interfaces/bff';
import { getSurveyFiltersDV } from '@/services/dashboardServiceDV';
import { getSurveyFilters } from '@/services/surveyFiltersService';
import { BaseParamsWithLocale } from '@/interfaces/services';

export const getDefaultStateSurveyFilters = (): SurveyFilterStoreState => ({
    surveyFilters: [],
    selectedFilterScales: [],
});

const surveyFilterStore = {
    namespaced: true,
    state: getDefaultStateSurveyFilters(),
    mutations: {
        resetState(state: SurveyFilterStoreState): void {
            Object.assign(state, getDefaultStateSurveyFilters());
        },
        setSurveyFilters(state: SurveyFilterStoreState, surveyFilters: BffSurveyFilter[]): void {
            state.surveyFilters = surveyFilters;
        },
        setSelectedFilterScales(state: SurveyFilterStoreState, selectedScales: selectedFilterScale[]): void {
            state.selectedFilterScales = selectedScales;
        },
        deleteFilterScale(state: SurveyFilterStoreState, index: number): void {
            state.selectedFilterScales.splice(index, 1);
        },
    },
    actions: {
        async loadSurveyFilters({
            commit,
            dispatch,
            rootState,
            rootGetters,
        }: ActionContext<SurveyFilterStoreState, any>): Promise<any> {
            const params: BaseParamsWithLocale = {
                instanceId: rootState.instance.instanceId,
                waveId: rootState.instance.waveId,
                locale: rootState.instance.locale,
            };

            let request;

            if (rootGetters['instance/isDVDashboard']) {
                request = getSurveyFiltersDV(
                    params.instanceId,
                    params.waveId,
                    params.locale,
                );
            } else {
                request = getSurveyFilters(params);
            }

            return request
                .then((res) => {
                    if (rootGetters['instance/isDVDashboard']) {
                        commit('setSurveyFilters', res.data.filters);
                    } else {
                        commit('setSurveyFilters', res.data.items);

                        if (rootGetters['instance/isQuestionOnlyTemplate']) {
                            const configFilters = {
                                settingKey: 'listFilterVariables',
                                settingValue: res.data.filters,
                            };
                            const configSplits = {
                                settingKey: 'breakoutVariables',
                                settingValue: res.data.breakouts,
                            };

                            commit('instance/setSetting', configFilters, { root: true });
                            commit('instance/setSetting', configSplits, { root: true });
                        }

                        if (rootGetters['instance/isMultiCategoryTemplate']) {
                            const configSplits = {
                                settingKey: 'widgetFilterVariables',
                                settingValue: res.data.breakouts,
                            };

                            commit('instance/setSetting', configSplits, { root: true });
                        }
                    }
                }).catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },
    },
};

export default surveyFilterStore;
