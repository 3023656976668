import httpClient from '@/clients/httpClient';
import {
    BffOpenQuestionsResponse,
} from '@/interfaces/bff';
import {
    raterGroupAll,
} from '@/constants';
import {
    validateEmpty,
    addFiltersToParams,
} from '@/services/utilities';
import {
    CommentListFiltersPayload,
    CommentListParams,
    CommentListPayload,
    CommentListRaterPayload,
} from '@/interfaces/services';

/* URLs */
const getOverviewCommentListUrl = '/api/instances/{instanceId}/comments';
const getCategoryCommentListUrl = '/api/instances/{instanceId}/categories/{category}/comments';

/*
   * Comment list component
   * Used on comment list page for all "default" templates
   * Used on overview page for all "default" templates (for Comments widget)
 */
// eslint-disable-next-line import/prefer-default-export
export const getOpenQuestions = async ({
    source,
    instanceId,
    waveId,
    category,
    locale,
    selectedFilterScales,
    raterGroup,
}: CommentListParams): Promise<BffOpenQuestionsResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    let params: CommentListPayload | CommentListFiltersPayload | CommentListRaterPayload = {
        waveId,
        locale,
        raterGroup: (raterGroup && raterGroup !== raterGroupAll) ? raterGroup : undefined,
    };

    if (selectedFilterScales.length) {
        const filterParams = addFiltersToParams(selectedFilterScales);
        params = { ...params, ...filterParams };
    }

    let url = getOverviewCommentListUrl.replace('{instanceId}', instanceId);
    if (category) {
        url = getCategoryCommentListUrl;
        url = url.replace('{category}', category);
    }

    return httpClient.get(
        url,
        {
            cancelToken: source?.token,
            params,
        },
    )
        .then((response: BffOpenQuestionsResponse) => response);
};
