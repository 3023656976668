import { ActionContext } from 'vuex';
import { ErrorStoreState } from '@/interfaces/stores';
import { InternalError } from '@/helpers/error';

export const getDefaultStateError = (): ErrorStoreState => ({
    showError: false,
    errorMessage: '',
    errorType: '',
    errorDetails: '',
});

const errorStore = {
    namespaced: true,
    state: getDefaultStateError(),
    mutations: {
        resetState(): void {
            // Do nothing
        },
        setError(state: ErrorStoreState, error: Error|InternalError): void {
            state.errorType = error.name;
            if (error.message) {
                state.errorMessage = error.message;
            }
            /*  istanbul ignore else */
            if (!(error instanceof InternalError) || error.details) {
                state.errorDetails = !(error instanceof InternalError) || error.details;
            }
            state.showError = true;
        },
    },
    actions: {
        errorReceived(
            { dispatch, commit, state }: ActionContext<ErrorStoreState, any>,
            error: Record<string, string>,
        ): void {
            // console.log('Dashbo UI error', error);
            if (!state.showError) {
                if (error.name === 'AuthenticationError' && error.type === 'expired') {
                    const date = Date.now();
                    dispatch('authentication/invalidTokenReceived', { url: error.url, date }, { root: true });
                } else {
                    commit('setError', error);
                }
            }
        },
    },
};

export default errorStore;
