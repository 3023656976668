import { ExternalVuetifyTheme } from '@/interfaces/externalVuetifyTheme';

import { basic } from '@/constants/themes/basic';
import { neutralTheme1 } from '@/constants/themes/neutralTheme1';
import { neutralTheme4 } from '@/constants/themes/neutralTheme4';

export default <Record<string, ExternalVuetifyTheme>> {
    basic,
    neutral: neutralTheme1,
    neutralTheme4,
};

export const themeSelectorInventory = [
    { text: 'Basic', value: 'basic' },
    { text: 'Tivian Neutral Theme 4', value: 'neutralTheme4' },
];
