import { BffRaterGroupsResponse, RaterGroupsData, RaterGroup } from '@/interfaces/bff';
import { localeString } from '@/fixtures';

export const raterGroupAll = {
    metaname: 'all_sum',
    label: 'All',
};

export const raterGroupSelf = {
    metaname: 'sys_rg_self_assessment',
    label: 'Self-Assessment',
};

export const raterGroupOther = {
    metaname: 'sys_rg_other',
    label: 'Other',
};

export const raterGroupTeam2 = {
    metaname: 'sys_rg_team2',
    label: 'Team2',
};

export const raterGroupList: RaterGroup[] = [
    {
        metaname: 'sys_rg_team',
        label: 'Team',
    },
    {
        metaname: 'sys_rg_peers',
        label: 'Peers',
    },
];

export const raterGroupsForTabs = [ raterGroupAll, ...raterGroupList, raterGroupOther];

export const raterGroupsFullList = [ raterGroupAll, ...raterGroupList, raterGroupOther];

export const raterGroupsFullListExtended = [ raterGroupSelf, raterGroupAll, ...raterGroupList, raterGroupTeam2, raterGroupOther];

export const raterGroupsFullWithoutAll = [raterGroupSelf, ...raterGroupList];

export const raterGroupsData: RaterGroupsData = {
    locale: localeString,
    raterGroups: [raterGroupSelf, ...raterGroupList],
}

export const raterGroupsResponse: BffRaterGroupsResponse = {
    status: 200,
    data: raterGroupsData,
};

export const raterGroupsFullData: RaterGroupsData = {
    locale: localeString,
    raterGroups: raterGroupsFullWithoutAll,
}

export const raterGroupsFullResponse: BffRaterGroupsResponse = {
    status: 200,
    data: raterGroupsFullData,
};
