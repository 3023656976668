import {
    BffResponseRatesResponse,
} from '@/interfaces/bff';

import httpClient from '@/clients/httpClient';
import {
    validateEmpty,
} from '@/services/utilities';
import { BaseParams } from '@/interfaces/services';

/* URLs */
export const GetResponseRateUrl = '/api/instances/{instanceId}/response-rate';

/*
   * Response rate widget
   * Used on overview page for all templates of type "default"
 */
export const getResponseRate = async ({
    instanceId,
    waveId,
}: BaseParams): Promise<BffResponseRatesResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params = { waveId };

    let url = GetResponseRateUrl;
    url = url.replace('{instanceId}', instanceId);

    return httpClient.get(
        url,
        { params },
    )
        .then((response: BffResponseRatesResponse) => response);
};
