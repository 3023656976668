import httpClient from '@/clients/httpClient';
import { BffNPSResponse } from '@/interfaces/bff';
import { validateEmpty } from '@/services/utilities';
import { BaseParamsWithLocale } from '@/interfaces/services';

/* URLs */
export const getNPSUrl = '/api/instances/{instanceId}/nps';

/*
   * NPS widget
   * Used on overview page for vibe template
 */
export const getNPS = async ({
    instanceId,
    waveId,
    locale,
}: BaseParamsWithLocale): Promise<BffNPSResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params = { waveId, locale };
    let url = getNPSUrl;
    url = url.replace('{instanceId}', instanceId);

    return httpClient.get(
        url,
        { params },
    )
        .then((response: BffNPSResponse) => response);
};
